import * as React from 'react'
import styled from 'theme/styled-components'

import { CategoryIcon } from './svg/TransportIcon'

import Loader from 'components/status/Loader'
import Drawer from 'components/drawer/Drawer'
import Main from 'components/main/Main'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'

import useI18n from 'i18n/useI18n'
import * as SiteStore from 'site/store'
import useReducer from 'store/useReducer'
import * as UserStore from 'store/user/user'

import TransportItem from './TransportItem'
import FavoriteTransports from './FavoriteTransports'

import api from './api'

import { breakpoints } from 'utils/breakpoints'

import { getExternalLinks, compareSameCategoryLines } from './utils'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

const REFRESH_DELAY = 60
const SORT_CATEGORIES = ['TRAIN', 'METRO', 'BUS', 'TRAMWAY', 'RER']

const TransportScreen = () => {
  const i18n = useI18n()

  const site = useReducer(SiteStore.store, (s) => s.site)
  const user = useReducer(UserStore.store, (s) => s.user)

  const [traffic, setTraffic] = React.useState<TransportStations>()
  const [trafficStatus, setTrafficStatus] = React.useState<'loading' | 'error' | 'ok'>('loading')

  const stationsInfo: TransportTrafficStation[] = React.useMemo(() => {
    if (traffic) {
      return traffic.stations
        .reduce((acc, { name, line, category }) => {
          const index = acc.findIndex((o) => o.name.toLowerCase() === name.toLowerCase())
          if (index > -1) {
            const lineAlreadyAdded = !!acc[index].lines.find((o) => o.name === line)
            if (!lineAlreadyAdded && !!category) {
              acc[index].lines.push({ name: line, category: category })
            }
          } else if (!!category) {
            acc.push({ name: name, lines: [{ name: line, category: category }] })
          }
          return acc
        }, [] as TransportTrafficStation[])
        .sort((s1, s2) => (s1.name < s2.name ? -1 : 1))
    }
    return []
  }, [traffic])

  const sortCategories = (a: TransportStation, b: TransportStation) => {
    if (!!a.category && !!b.category) {
      const indexA = SORT_CATEGORIES.indexOf(a.category)
      const indexB = SORT_CATEGORIES.indexOf(b.category)
      return indexA - indexB
    }
    return 0
  }

  const displayedStations: TransportStation[] | undefined = React.useMemo(
    () =>
      traffic?.stations
        .reduce((acc, cur) => {
          cur.schedules.forEach((sch) => {
            const directionIndex = acc.findIndex(
              (item) =>
                item.schedules[0].direction === sch.direction && item.name === cur.name && item.line === cur.line
            )
            if (directionIndex < 0) {
              // Ajout des horaires d'une direction
              acc.push({ ...cur, schedules: cur.schedules.filter((s) => s.direction === sch.direction) })
            }
          })
          return acc
        }, [] as TransportStation[])
        .sort(sortCategories) // On trie les lignes par catégorie
        .sort(
          (a, b) =>
            a.category === b.category
              ? a.line === b.line // On trie les lignes de même catégorie sur leurs noms puis leurs directions
                ? a.schedules[0].direction.localeCompare(b.schedules[0].direction)
                : compareSameCategoryLines(a.line, b.line)
              : 0 // On ne trie pas les lignes si leurs catégories sont différentes
        ),
    [traffic]
  )

  React.useEffect(() => {
    refresh()
    const interval = setInterval(() => {
      refresh()
    }, REFRESH_DELAY * 1000)

    return () => clearInterval(interval)
  }, [])

  React.useEffect(() => {
    if (!!site) {
      analytics.event(values.eventName.functionnality, {
        ecran: values.screens.transport,
        site: site.name,
        type_utilisateur: analytics.typeUser(),
      })
    }
  }, [site])

  const refresh = () => {
    if (!!site && !!user) {
      setTrafficStatus('loading')
      api
        .arrivals(site.id)
        .then((result) => {
          setTrafficStatus('ok')
          setTraffic(result)
        })
        .catch(() => setTrafficStatus('error'))
    }
  }

  const renderStation = (station: TransportTrafficStation) => {
    const stationLines = displayedStations?.filter((s) => s.name.toLowerCase() === station.name.toLowerCase()) || []
    return (
      <StyledListItem key={station.name}>
        <TransportItem stationName={station.name} stationLines={stationLines} />
      </StyledListItem>
    )
  }

  const renderHeader = () => (
    <Header>
      <HeaderTitle>{i18n.t('screens.transport.title')}</HeaderTitle>

      <Actions>
        {/* CITYMAPPER */}
        <ActionLink href={getExternalLinks(site)['citymapper']} target="_blank">
          <ActionLogo>
            <CategoryIcon name="citymapper" size={44} cursor="pointer" />
          </ActionLogo>
          <ActionText>{i18n.t('screens.transport.citymapper')}</ActionText>
        </ActionLink>

        {/* WAZE */}
        <ActionLink href={getExternalLinks(site)['waze']} target="_blank">
          <ActionLogo>
            <CategoryIcon name="waze" size={44} cursor="pointer" />
          </ActionLogo>
          <ActionText>{i18n.t('screens.transport.waze')}</ActionText>
        </ActionLink>
      </Actions>
    </Header>
  )

  if (!traffic) {
    return (
      <ScreenContainer>
        <TitleHelmet title={i18n.t('screens.transport.title')} />
        <Main>
          <>
            {renderHeader()}
            {trafficStatus === 'loading' ? (
              <Loader />
            ) : (
              <NoStation>
                <NoInfo>{i18n.t('screens.transport.errors.noStation')}</NoInfo>
              </NoStation>
            )}
          </>
        </Main>
      </ScreenContainer>
    )
  }

  return (
    <ScreenContainer>
      <TitleHelmet title={i18n.t('screens.transport.title')} />

      <Main>
        <>
          {renderHeader()}
          <StationsContainer>
            <SubTitle>{i18n.t('screens.transport.allLines')}</SubTitle>
            <StyledList>
              {stationsInfo.length > 0 ? (
                stationsInfo.map(renderStation)
              ) : (
                <NoInfoContainer>
                  <NoInfo>{i18n.t('screens.transport.errors.noStation')}</NoInfo>
                </NoInfoContainer>
              )}
            </StyledList>
          </StationsContainer>
        </>
      </Main>

      <Drawer icon="star">
        <FavoriteTransports displayedStations={displayedStations} />
      </Drawer>
    </ScreenContainer>
  )
}

export default TransportScreen

// CONTAINERS

const ScreenContainer = styled('div')`
  display: flex;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.contentBackground};
`

const Header = styled('div')`
  background-color: ${(props) => props.theme.colors.white};
  padding: 50px 400px 0px 80px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 50px 0px 80px;
  }
`

const Actions = styled('div')`
  flex: 1;
  padding: 10px 200px;
  flex-direction: row;
  justify-content: center;

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 30px 50px 10px;
  }
`

const ActionLink = styled('a')`
  text-decoration: none;
  flex-direction: column;
  width: 80px;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
  &:hover {
    cursor: pointer;
  }
`

const ActionLogo = styled('div')`
  padding-left: 16px;
  width: 64px;
  align-items: center;
  justify-content: center;
`

const StationsContainer = styled('div')`
  padding: 50px 400px 50px 50px;
`

const NoInfoContainer = styled('div')`
  margin: 16px 0px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const NoStation = styled(NoInfoContainer)`
  height: 100vh;
  flex: 1;
  background-color: ${(props) => props.theme.colors.contentBackground};
`

// TEXTS

const HeaderTitle = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.primaryDark};
  flex: 1;
  margin: 0px;
`

const SubTitle = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 0px 0px 38px 24px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    margin-right: -350px;
  }
`

const ActionText = styled('p')`
  ${(props) => props.theme.fonts.link};
  color: ${(props) => props.theme.colors.primaryDark};
  text-align: center;
  text-decoration: none;
  margin-top: 10px;
`

const NoInfo = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  padding-left: 8px;
  text-align: center;
`

// LISTS

const StyledList = styled('ul')`
  display: grid;
  list-style: none;
  padding: 0;
  margin: 0;
  grid-template-columns: repeat(auto-fit, 420px);
`

const StyledListItem = styled('li')`
  margin: 0px 24px;
  display: grid;
`
