import firebase from 'firebase/app'
import 'firebase/analytics'

export default {
  onScreen: (name: string, clss: string) => {
    firebase.analytics().setCurrentScreen(name)
  },
  event: (eventName: string, tags: { [key: string]: string | number | boolean }) => {
    firebase.analytics().logEvent(eventName, tags)
  },
  typeUser: () => 'Interne',
}
