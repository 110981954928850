import HomeScreen from 'home/HomeScreen'
import BookingScreen from 'meeting/booking/BookingScreen'
import MeetingIntermediateScreen from 'meeting/MeetingIntermediateScreen'
import CateringScreen from 'meeting/catering/CateringScreen'
import CateringDetailScreen from 'meeting/catering/CateringDetailScreen'
import NewsScreen from 'news/NewsScreen'
import NewsDetailScreen from 'news/NewsDetailScreen'
import EditosScreen from 'practicalinformation/EditosScreen'
import EditoDetailScreen from 'practicalinformation/EditoDetailScreen'
import RestaurantsScreen from 'restaurant/RestaurantsScreen'
import RestaurantDetailScreen from 'restaurant/RestaurantDetailScreen'
import MapScreen from 'visioglobe/MapScreen'
import CGUScreen from 'cgu/CGUScreen'
import WebviewFolderScreen from 'webview/WebviewFolderScreen'
import AccessibilityScreen from 'accessibility/AccessibilityScreen'
import TransportScreen from 'transport/TransportScreen'
import SurveyScreen from 'survey/SurveyScreen'
import SurveyDetailScreen from 'survey/SurveyDetailScreen'

import LoginScreen from 'login/LoginScreen'
import SiteSelectScreen from 'site/SiteSelectScreen'
import DirectoryScreen from 'directory365/DirectoryScreen'
import GuestScreen from 'guest/GuestScreen'
import EditVisit from 'guest/EditVisitScreen'

import InterCampusScreen from 'move/InterCampusScreen'
import MobilityScreen from 'move/MobilityScreen'
import MoveScreen from 'move/MoveScreen'

import SafeAtWorkScreen from 'safeatwork/SafeAtWorkScreen'
import SafeAtWorkDetailScreen from 'safeatwork/SafeAtWorkDetailScreen'

import RegisterScreen from 'register/RegisterScreen'
import RegisterDetailScreen from 'register/RegisterDetailScreen'

const screens: Record<ScreenName, ScreenDefinition> = {
  login: {
    name: 'login',
    icon: 'user',
    screen: LoginScreen as RootScreen,
    path: '/login',
    auth: false,
  },
  site: {
    name: 'site',
    icon: 'user',
    screen: SiteSelectScreen as RootScreen,
    path: '/site',
    auth: false,
  },
  home: {
    name: 'home',
    icon: 'home',
    screen: HomeScreen as RootScreen,
    path: '/',
    auth: true,
  },
  phonebook: {
    name: 'phonebook',
    icon: 'directory',
    screen: DirectoryScreen as RootScreen,
    path: '/phonebook',
    auth: true,
    altIcon: 'directory_alt',
    feature: 'phonebook',
  },
  guest: {
    name: 'guest',
    icon: 'information_desk',
    screen: GuestScreen as RootScreen,
    path: '/guest',
    auth: true,
  },
  editVisit: {
    name: 'editVisit',
    icon: 'information_desk',
    screen: EditVisit as RootScreen,
    path: '/editVisit/:id',
    auth: true,
  },
  news: {
    name: 'news',
    icon: 'news',
    screen: NewsScreen as RootScreen,
    path: '/news',
    auth: true,
    altIcon: 'news_alt',
    feature: 'news',
  },
  newsDetail: {
    name: 'newsDetail',
    icon: 'news',
    screen: NewsDetailScreen as RootScreen,
    path: '/news/:id',
    auth: true,
    feature: 'news',
  },
  practicalinformation: {
    name: 'practicalinformation',
    icon: 'info',
    screen: EditosScreen as RootScreen,
    path: '/practicalinformation',
    auth: true,
    altIcon: 'info_alt',
    feature: 'practicalinformation',
  },
  editoDetail: {
    name: 'editoDetail',
    icon: 'info',
    screen: EditoDetailScreen as RootScreen,
    path: '/practicalinformation/:id',
    auth: true,
    feature: 'practicalinformation',
  },
  safeAtWork: {
    name: 'safeAtWork',
    icon: 'safe',
    screen: (SafeAtWorkScreen as unknown) as RootScreen,
    path: '/safeAtWork',
    auth: true,
    feature: 'safeAtWork',
  },
  safeAtWorkDetail: {
    name: 'safeAtWorkDetail',
    icon: 'safe',
    screen: (SafeAtWorkDetailScreen as unknown) as RootScreen,
    path: '/safeAtWork/:id',
    auth: true,
    feature: 'safeAtWork',
  },
  restaurant: {
    name: 'restaurant',
    icon: 'restaurant',
    screen: RestaurantsScreen as RootScreen,
    path: '/restaurants',
    auth: true,
    altIcon: 'restaurant_alt',
    feature: 'restaurant',
  },
  restaurantDetail: {
    name: 'restaurantDetail',
    icon: 'restaurant',
    screen: RestaurantDetailScreen as RootScreen,
    path: '/restaurants/:id',
    auth: true,
    feature: 'restaurant',
  },
  booking: {
    name: 'booking',
    icon: 'desk',
    screen: BookingScreen as RootScreen,
    path: '/meeting/booking',
    auth: true,
    altIcon: 'desk_alt',
    feature: 'meeting',
  },
  meeting: {
    name: 'meeting',
    icon: 'desk',
    screen: MeetingIntermediateScreen as RootScreen,
    path: '/meeting',
    auth: true,
    altIcon: 'desk_alt',
    feature: 'meeting',
  },
  catering: {
    name: 'catering',
    icon: 'desk',
    screen: CateringScreen as RootScreen,
    path: '/meeting/catering',
    auth: true,
    altIcon: 'desk_alt',
    feature: 'meeting',
  },
  cateringDetail: {
    name: 'cateringDetail',
    icon: 'desk',
    screen: CateringDetailScreen as RootScreen,
    path: '/meeting/catering/:id',
    auth: true,
    altIcon: 'desk_alt',
    feature: 'meeting',
  },
  map: {
    name: 'map',
    icon: 'pin',
    screen: MapScreen as RootScreen,
    path: '/map',
    auth: true,
    altIcon: 'pin_alt',
    feature: 'map',
  },
  cgu: {
    name: 'cgu',
    icon: 'user',
    screen: CGUScreen as RootScreen,
    path: '/acceptcgu',
    auth: false,
    feature: 'cgu',
  },
  consultCgu: {
    name: 'consultCgu',
    icon: 'user',
    screen: CGUScreen as RootScreen,
    path: '/cgu',
    auth: true,
    feature: 'cgu',
  },
  webview: {
    name: 'webview',
    icon: 'user',
    screen: WebviewFolderScreen as RootScreen,
    path: '/content/:folder',
    auth: true,
  },
  accessibility: {
    name: 'accessibility',
    icon: 'info',
    screen: AccessibilityScreen as RootScreen,
    path: '/accessibility',
    auth: true,
  },
  transport: {
    name: 'transport',
    icon: 'transport',
    screen: TransportScreen as RootScreen,
    path: '/transport',
    auth: true,
    altIcon: 'transport_alt',
    feature: 'move',
  },
  intercampus: {
    name: 'intercampus',
    icon: 'route',
    altIcon: 'route',
    screen: (InterCampusScreen as unknown) as RootScreen,
    path: '/move/intercampus',
    auth: true,
    feature: 'move',
  },
  mobility: {
    name: 'mobility',
    icon: 'mobility',
    altIcon: 'mobility',
    screen: (MobilityScreen as unknown) as RootScreen,
    path: '/move/transport',
    auth: true,
    feature: 'move',
  },
  move: {
    name: 'move',
    icon: 'transport',
    altIcon: 'transport_alt',
    screen: (MoveScreen as unknown) as RootScreen,
    path: '/move',
    auth: true,
    feature: 'move',
  },
  survey: {
    name: 'survey',
    icon: 'survey',
    screen: SurveyScreen as RootScreen,
    path: '/survey',
    auth: true,
    feature: 'survey',
  },
  surveyDetail: {
    name: 'surveyDetail',
    icon: 'survey',
    screen: SurveyDetailScreen as RootScreen,
    path: '/survey/:id',
    auth: true,
    feature: 'survey',
  },
  register: {
    name: 'register',
    icon: 'register',
    screen: (RegisterScreen as unknown) as RootScreen,
    path: '/register',
    auth: true,
    feature: 'register',
  },
  registerDetail: {
    name: 'registerDetail',
    icon: 'register',
    screen: (RegisterDetailScreen as unknown) as RootScreen,
    path: '/register/:id/:bookingId?',
    auth: true,
    feature: 'register',
  },
}

export default screens
