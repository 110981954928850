import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Animator from 'components/animation/Animator'
import Loader from 'components/status/Loader'
import Tree from 'components/button/Tree'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'

import { breakpoints } from 'utils/breakpoints'
import useReducer from 'store/useReducer'
import useI18n from 'i18n/useI18n'

import * as SitesStore from 'site/store'

import api from 'practicalinformation/api'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  id: string
}

const SafeAtWorkDetailScreen = ({ id }: Props) => {
  const [theme] = useTheme()

  const i18n = useI18n()
  const [edito, setEdito] = React.useState<PracticalInformationDetails>()
  const [loading, setLoading] = React.useState(false)

  const site = useReducer(SitesStore.store, (s) => s.site)

  const photos = React.useMemo(
    () =>
      edito?.photos
        ?.filter((p) => !!p.url)
        .sort((a, b) => a.position - b.position)
        .map((p) => ({ key: p.url, url: p.url, description: p.photoDescription })),
    [edito]
  )

  const fetch = () => {
    api
      .get(i18n.lang, id, 'CONTENT')
      .then(setEdito)
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    if (!!site) {
      analytics.event(values.eventName.safeAtWork, {
        vue: id,
        site: site.name,
        type_utilisateur: analytics.typeUser(),
      })
    }
  }, [])

  React.useEffect(() => {
    setLoading(true)
    fetch()
  }, [id])

  if (loading) {
    return <Loader />
  }

  if (!edito) {
    return null
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={`${i18n.t('screens.safeAtWork.titleDetailPage')}${edito.title}`} />

        <Tree urlEnd="safeAtWork" previousPageTitle="screens.safeAtWork.title" currentPageTitle={edito.title} />

        <ContentContainer>
          <Animator>
            <Title>{edito.title}</Title>
            <ImageContainer>
              <ItemImage src={!!photos ? photos[0].url : require('core/src/assets/news_default.jpeg').default} />
            </ImageContainer>

            <Content
              dangerouslySetInnerHTML={{
                __html: edito.description
                  .replace(/href/g, "target='_blank' href")
                  .replace(/<a/g, `<a style="color: ${theme.colors.secondary}; text-decoration: none"`),
              }}
            />
          </Animator>
        </ContentContainer>
      </ScreenContainer>
    </Main>
  )
}

export default SafeAtWorkDetailScreen

const ScreenContainer = styled('div')`
  display: flex;
  padding: 44px 80px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 44px 55px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 44px 35px;
  }
`

const ContentContainer = styled('div')`
  padding: 40px 200px;
  display: flex;
  flex: 1;
  width: 645px;
  justify-content: center;
  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 20px 0px;
    width: 100%;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 20px 0px;
    width: 100%;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h1Bold};
  margin: 0px 0px 30px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-wrap: break-word;
`

const Content = styled('div')`
  ${(props) => props.theme.fonts.h3};
  padding-top: 24px;
`

const ImageContainer = styled('div')`
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 297px;
  width: 400px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.functionalities.practicalinformation}0D;

  @media only screen and (max-width: ${breakpoints.small}px) {
    height: 297px;
    width: 100%;
  }
`

const ItemImage = styled('img')`
  object-fit: cover;
  user-select: none;
  aspect-ratio: 4/3;
  height: 297px;
  width: 400px;
  border-radius: 12px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    height: 297px;
    width: 100%;
  }
`
