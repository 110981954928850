import * as React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

import * as SiteStore from 'site/store'
import useReducer from 'store/useReducer'

import useNavigation from 'core/src/layout/useNavigation'

import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main, { HEADER_HEIGHT } from 'components/main/Main'
import Animator from 'components/animation/Animator'
import AlertPortal from 'components/alert/Alert'
import Drawer, { DRAWER_WIDTH } from 'components/drawer/Drawer'

import CateringForm, { FormValues } from './CateringForm'
import MyInstantsList from './MyInstantsList'

import api from './api'

import * as CateringStore from './store'

import { breakpoints } from 'utils/breakpoints'
import Logger from 'utils/Logger'

import { FormikConfig } from 'formik'
import { format } from 'date-fns'

const CateringScreen = () => {
  const i18n = useI18n()
  const navigation = useNavigation()

  const currentSite = useReducer(SiteStore.store, (s) => s.site)

  const [drawerOpened, setDrawerOpened] = React.useState(false)

  const onSubmit: FormikConfig<Partial<FormValues>>['onSubmit'] = (form, helpers) => {
    if (
      currentSite &&
      form.endingTime &&
      form.startingTime &&
      form.date &&
      form.numberGuests !== undefined &&
      form.instantType &&
      form.cateringPlace &&
      (form.costCenter?.id || form.costCenterId)
    ) {
      api
        .createCateringInstant({
          startingTime: format(form.startingTime, 'HH:mm'),
          endingTime: format(form.endingTime, 'HH:mm'),
          numberGuests: form.numberGuests,
          siteId: currentSite.id.toString(),
          additionalArticles: form.additionalArticles?.map((a) => ({ id: a.id, quantity: a.quantity })),
          comment: form.comment,
          date: format(form.date, 'dd-MM-yyyy'),
          costCenterId: (form.costCenterId || form.costCenter?.id) as string,
          instantTypeId: form.instantType.id,
          referentielReference: form.cateringPlace.referentielReference,
        })
        .then((detail) => {
          AlertPortal.open({
            title: i18n.t('screens.meeting.catering.form.submitSuccess'),
            description: i18n.t('screens.meeting.catering.form.submitSuccessDescription'),
          })
          // Mise à jour de la liste des instants commandés
          api.getCateringInstants().then(({ instants }) => CateringStore.actions.setInstants(instants))

          helpers.resetForm()
          helpers.setFieldValue('comment', '')

          // Redirection vers l'écran de détail de l'instant commandé
          navigation.push(`/meeting/catering/${detail.id}`)
        })
        .catch((err) => {
          Logger.error(err)
          AlertPortal.open({
            title: i18n.t('screens.meeting.catering.form.submitError'),
            description: i18n.t('screens.meeting.catering.form.submitErrorDescription'),
          })
        })
    }
  }

  return (
    <ScreenContainer>
      <TitleHelmet title={i18n.t('screens.meeting.catering.title')} />
      <Main>
        <MainContainer drawerOpened={drawerOpened}>
          <Animator>
            <Title>{i18n.t('screens.meeting.catering.title')}</Title>
            <CateringForm onSubmit={onSubmit} mode="CREATE" />
          </Animator>
        </MainContainer>
      </Main>

      <Drawer icon="roombooking" closingWidth={1100} onToggle={setDrawerOpened}>
        <MyInstantsList />
      </Drawer>
    </ScreenContainer>
  )
}

export default CateringScreen

const ScreenContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  overflow: auto;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    overflow-x: hidden;
    position: relative;
    width: 100vw;
  }
`

const MainContainer = styled.div<{ drawerOpened: boolean }>`
  padding: 50px 165px;
  margin-right: ${(props) => (props.drawerOpened ? DRAWER_WIDTH - 165 : 0)}px;

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 50px 90px;
    margin-right: ${(props) => (props.drawerOpened ? DRAWER_WIDTH - 90 : 0)}px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px;
    margin-right: 0px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
    margin-right: 0px;
  }
`

const Title = styled.h1`
  ${(props) => props.theme.fonts.h2Bold};
  margin-bottom: 50px;
`
