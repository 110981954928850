import * as React from 'react'
import styled from 'theme/styled-components'

import HomeHeader from './HomeHeader'
import HomeContent from 'core/src/components/HomeContent'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'

import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'
import * as ThemeStore from 'theme/store'

import { breakpoints } from 'utils/breakpoints'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

import { OPENED_WIDTH, CLOSED_WIDTH } from 'core/src/layout/Drawer'

const IMG_HEIGHT = window.innerHeight / 3
const MARGIN_TOP = IMG_HEIGHT / 2
const SCROLL_OFFSET = -20

interface EventScrollTarget extends EventTarget {
  scrollTop?: number
}

const HomeScreen = () => {
  const site = useReducer(SiteStore.store, (s) => s.site!)
  const drawerBehavior = useReducer(ThemeStore.store, (s) => s.drawer)

  const [imgPosition, setImgPosition] = React.useState<'fixed' | 'absolute'>('fixed')

  // Valeur du scroll du contenu, à partir de laquelle l'arrière-plan scrolle aussi
  const [minScroll, setMinScroll] = React.useState(0)

  const imgContainerRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    analytics.event(values.eventName.functionnality, {
      ecran: values.screens.home,
      site: site.name,
      type_utilisateur: analytics.typeUser(),
    })
  }, [])

  React.useEffect(() => {
    const screenElement = document.getElementById('screenContainer')
    if (!!screenElement) {
      screenElement.addEventListener('scroll', onScroll)
    }
    return () => {
      if (!!screenElement) {
        screenElement.removeEventListener('scroll', onScroll)
      }
    }
  }, [minScroll])

  const onScroll = (e: Event) => {
    const scrollValue = (e.currentTarget as EventScrollTarget)?.scrollTop || 0
    setImgPosition(scrollValue > minScroll ? 'absolute' : 'fixed')
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={`Site : ${site.name}`} />

        <ImageContainer role="banner" ref={imgContainerRef} position={imgPosition} top={minScroll}>
          <SiteImage
            src={
              site.backgroundImages.length === 1
                ? site.backgroundImages[0].url
                : site.image || require('assets/building_default.jpeg').default
            }
            alt={site.backgroundImages.length === 1 ? site.backgroundImages[0].alt : ''}
            drawerBehavior={drawerBehavior}
          />
        </ImageContainer>

        <HeaderContainer>
          <HomeHeader
            siteId={site.id}
            onHeightChange={(height) => setMinScroll(Math.max(0, height - MARGIN_TOP + SCROLL_OFFSET))}
          />
        </HeaderContainer>

        <ContentContainer>
          <HomeContent siteId={site.id} />
        </ContentContainer>
      </ScreenContainer>
    </Main>
  )
}

export default HomeScreen

const ScreenContainer = styled('div')`
  position: relative;
  justify-content: space-between;
  min-height: ${window.innerHeight}px;
`

// Site image

const ImageContainer = styled('header')<{ position: 'fixed' | 'absolute'; top: number }>`
  position: ${(props) => props.position};
  ${(props) => props.position === 'absolute' && `top: ${props.top}px`};
  z-index: inherit;
`

const SiteImage = styled('img')<{ drawerBehavior: DrawerBehavior }>`
  height: ${IMG_HEIGHT}px;
  width: calc(100vw - ${(props) => (props.drawerBehavior !== 'close' ? OPENED_WIDTH : CLOSED_WIDTH)}px);
  object-fit: cover;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    width: calc(100vw - ${(props) => CLOSED_WIDTH}px);
  }

  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: 100vw;
  }
`

// Header

const HeaderContainer = styled('div')`
  margin: ${MARGIN_TOP}px 104px 0;
  transition: margin 0.25s ease-in-out;

  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    margin: ${MARGIN_TOP}px 60px 0;
  }

  @media only screen and (max-width: ${breakpoints.medium}px) {
    margin: ${MARGIN_TOP}px 20px 0;
  }
`

// Content

const ContentContainer = styled('div')`
  margin: 40px 124px;
  transition: margin 0.25s ease-in-out;

  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    margin: 40px 80px;
  }

  @media only screen and (max-width: ${breakpoints.medium}px) {
    margin: 40px;
  }
`
