import { createEvent, createStore } from 'effector'

export const actions = {
  setAnimations: createEvent<AnimationSimple[]>('setAnimations'),
  resetAnimations: createEvent('resetAnimations'),
  setReservations: createEvent<Booking[]>('setReservations'),
  resetReservations: createEvent('resetReservations'),
  reset: createEvent('reset'),
}

const initialState: RegisterState = {
  animations: [],
  reservations: [],
}

export const store = createStore<RegisterState>(initialState, { name: 'register' })
  .on(actions.setAnimations, (s, animations) => ({ ...s, animations }))
  .on(actions.resetAnimations, (s) => ({ ...s, animations: initialState.animations }))
  .on(actions.setReservations, (s, reservations) => ({ ...s, reservations }))
  .on(actions.resetReservations, (s) => ({ ...s, reservations: initialState.reservations }))
  .on(actions.reset, (s) => initialState)
