import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'
import IconButton from 'components/icons/IconButton'
import Button from 'components/button/Button'
import Animators from 'components/animation/Animator'
import Checkbox from 'components/button/Checkbox'
import InfiniteList from 'components/list/InfiniteList'

import * as SitesStore from 'site/store'
import useReducer from 'store/useReducer'

import useI18n from 'i18n/useI18n'

import useDebounce from 'utils/useDebounce'
import sanitize from 'utils/Sanitizer'
import { RouteNavigation } from './visioweb'
import { RouteAction } from './types'
import { parseReference } from './utils'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

type OpenType = 'from' | 'to' | undefined

interface Props {
  pois: Referentiel[]
  poi?: Referentiel
  nav?: RouteNavigation
  onRoute: (action: RouteAction) => Promise<void>
}

const Route = ({ pois, poi, nav, onRoute }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const site = useReducer(SitesStore.store, (s) => s.site)

  const [search, bounced, setSearch] = useDebounce('')
  const [open, setOpen] = React.useState<OpenType>()

  const [from, setFrom] = React.useState<Referentiel | undefined>()
  const [to, setTo] = React.useState<Referentiel | undefined>(poi)
  const [pmr, setPmr] = React.useState(false)

  const [step, setStep] = React.useState(-1)

  const steps = React.useMemo(() => (nav ? nav.getNbInstructions() : 0), [nav])
  const instructions = React.useMemo(
    () => (nav ? Array.from(new Array(steps)).map((_, idx) => nav.getInstructionData(idx)) : []),
    [nav, steps]
  )

  const items = React.useMemo(
    () =>
      pois.filter((p) => {
        const name = (p.data && p.data[`libelle_${i18n.lang}`]) || p.room || p.reference
        return sanitize(name).includes(sanitize(bounced))
      }),
    [pois, bounced]
  )

  React.useEffect(() => {
    setSearch('')
  }, [open])

  React.useEffect(() => {
    if (nav) {
      setStep(0)
    } else {
      setStep(-1)
    }
  }, [nav])

  const next = () => {
    if (nav && step < steps - 1) {
      nav.displayNextInstruction()
      setStep(step + 1)
    }
  }

  const previous = () => {
    if (nav && step > 0) {
      nav.displayPrevInstruction()
      setStep(step - 1)
    }
  }

  const onItemSelected = (item: Referentiel) => {
    if (open === 'from') {
      setFrom(item)
    } else if (open === 'to') {
      setTo(item)
    }

    setOpen(undefined)
  }

  const switchPositions = () => {
    setTo(from)
    setFrom(to)
  }

  if (!site) {
    return null
  }

  const startRoute = () => {
    if (from && to) {
      analytics.event(values.eventName.map, {
        action: values.actions.trajet,
        site: site.name,
        depart: from.reference,
        arrivee: to.reference,
        pmr: pmr ? 'Oui' : 'Non',
      })
      onRoute({ action: 'start', from: parseReference(from), to: parseReference(to), pmr })
    }
  }

  const stopRoute = () => {
    onRoute({ action: 'stop' })
  }

  const renderItem = React.useCallback(
    (item: Referentiel, shouldBeFocusable: boolean) => {
      const name = (item.data && item.data[`libelle_${i18n.lang}`]) || item.room || item.reference

      return (
        <Item tabIndex={shouldBeFocusable ? 0 : -1} key={item.id} onClick={() => onItemSelected(item)}>
          {name}
        </Item>
      )
    },
    [open]
  )

  const renderSearch = (isStart: boolean, open: OpenType) => {
    const shouldBeFocusable = (isStart && open === 'from') || (!isStart && open === 'to')
    return (
      <>
        <SearchContainer role="search">
          <InputContainer>
            <Input
              tabIndex={shouldBeFocusable ? 0 : -1}
              id={`idSearchRoom${isStart ? 'Start' : 'End'}`}
              value={search}
              onChange={(evt) => setSearch(evt.target.value)}
              placeholder={i18n.t('screens.map.search.holder')}
              title={i18n.t('accessibility.titleInputText.searchBar')}
              type="text"
            />
            {search.length > 0 && (
              <IconContainer>
                <IconButton
                  name="cross"
                  size={15}
                  color={Theme.colors.iconicGrey}
                  onClick={() => setSearch('')}
                  ariaLabel={i18n.t('accessibility.ariaLabels.deleteSearchInput')}
                />
              </IconContainer>
            )}
            <Icon name="search" color={Theme.colors.iconicGrey} size={15} />
          </InputContainer>
        </SearchContainer>
        {items.length === 0 ? (
          <Error>{i18n.t('screens.map.search.noResult')}</Error>
        ) : (
          <Items>
            <InfiniteList
              data={items}
              renderItem={(item) => renderItem(item, shouldBeFocusable)}
              height={60}
              size={{ height: 250, width: 375 }}
            />
          </Items>
        )}
      </>
    )
  }

  const renderPostion = (name: 'from' | 'to', pos?: Referentiel) => {
    const poiName = pos
      ? (pos.data && pos.data[`libelle_${i18n.lang}`]) || pos.room || pos.reference
      : i18n.t(`screens.map.route.${name}Holder`)

    return (
      <PositionItem
        onClick={() => setOpen(name)}
        aria-label={i18n.t(`accessibility.ariaLabels.mapDrawer.${name === 'to' ? 'end' : 'start'}DeskSelector`)}>
        <PositionInfos>
          <PositionTitle>
            <label htmlFor={`idSearchRoom${name === 'to' ? 'End' : 'Start'}`}>
              {i18n.t(`screens.map.route.${name}`)}
            </label>
          </PositionTitle>
          <PositionName>{poiName}</PositionName>
        </PositionInfos>
      </PositionItem>
    )
  }

  const renderForm = () => (
    <Animators>
      {renderPostion('from', from)}
      <OpenContainer open={open === 'from'}>{renderSearch(true, open)}</OpenContainer>

      <Switch>
        <IconButton
          name="updown"
          size={30}
          onClick={switchPositions}
          color={Theme.colors.secondary}
          height={28}
          ariaLabel={i18n.t('accessibility.ariaLabels.mapDrawer.switchStartEndRoute')}
        />
      </Switch>

      {renderPostion('to', to)}
      <OpenContainer open={open === 'to'}>{renderSearch(false, open)}</OpenContainer>

      <CheckContainer>
        <Checkbox
          active={pmr}
          onClick={() => setPmr(!pmr)}
          textColor={Theme.colors.secondary}
          label={i18n.t('screens.map.route.pmr')}
          ariaLabel={i18n.t(`accessibility.ariaLabels.mapDrawer.checkBoxPMR${pmr ? '' : 'Not'}Checked`)}
        />
      </CheckContainer>

      <ButtonContainer>
        <Button label={i18n.t('screens.map.poi.goTo')} onClick={startRoute} disabled={!from || !to} />
      </ButtonContainer>
    </Animators>
  )

  const renderNav = () => {
    if (!nav || step < 0) {
      return null
    }

    const current = instructions[step]

    if (!current) {
      return null
    }

    const isFirstStep = step === 0
    const isLastStep = step === steps - 1

    return (
      <Animators>
        <Message>{current.detail}</Message>
        <PointList>
          {Array.from(new Array(steps)).map((p, i) => (
            <Point key={i} active={i === step} />
          ))}
        </PointList>

        <NavigationLine>
          <NavigationButton
            tabIndex={isFirstStep ? -1 : 0}
            prev
            disable={isFirstStep}
            onClick={previous}
            aria-label={i18n.t('accessibility.ariaLabels.mapDrawer.navigationPreviousStep')}>
            <Icon name="chevron_right" size={15} color={Theme.colors.primaryDark} />
          </NavigationButton>
          <NavigationButton
            tabIndex={isLastStep ? -1 : 0}
            disable={isLastStep}
            onClick={next}
            aria-label={i18n.t('accessibility.ariaLabels.mapDrawer.navigationNextStep')}>
            <Icon name="chevron_right" size={15} color={Theme.colors.primaryDark} />
          </NavigationButton>
        </NavigationLine>

        <ButtonContainer>
          <Button label={i18n.t('screens.map.route.stop')} onClick={stopRoute} />
        </ButtonContainer>
      </Animators>
    )
  }

  return (
    <MainContainer>
      <Title>{i18n.t('screens.map.route.title')}</Title>

      {!!nav ? renderNav() : renderForm()}
    </MainContainer>
  )
}

export default Route

const MainContainer = styled('div')`
  flex: 1;
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  padding: 0px 20px;
  margin: 0px 0px 20px;
`

const PositionItem = styled('button')`
  display: flex;
  flex-direction: row;
  padding: 20px;
  cursor: pointer;
  border: 0px;
  background-color: ${(props) => props.theme.colors.background};
`

const PositionInfos = styled('div')`
  flex: 1;
  flex-direction: column;
  display: flex;
  align-self: flex-start;
`

const PositionTitle = styled('h2')`
  display: flex;
  align-self: flex-start;
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.secondary};
  margin: 0px;
`

const PositionName = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.middleGrey};
  display: flex;
  align-self: flex-start;
  margin: 0px;
`

const SearchContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.contentBackground};
  margin: 10px 0px;
  padding: 10px 20px;
`

const InputContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  border-radius: 50px;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
`

const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
`

const Input = styled('input')`
  ${(props) => props.theme.fonts.label};
  flex: 1;
  margin-right: 10px;
  border: none;
  outline: none;
`

const Items = styled('div')`
  padding: 25px 0px;
  flex: 1;
  max-height: -webkit-fill-available;

  display: block;
`

const Item = styled('button')`
  ${(props) => props.theme.fonts.body};
  padding: 10px 0px;
  padding-bottom: 25px;
  margin: 0px 20px;
  border: 0px;
  border-top-width: 1px;
  background-color: ${(props) => props.theme.colors.background};
  cursor: pointer;
`

const Switch = styled('div')`
  padding: 10px;
  align-items: center;
`

const OpenContainer = styled('div')<{ open: boolean }>`
  height: ${(props) => (props.open ? 314 : 0)}px;
  transition: height 0.5s ease-in-out;
  overflow: hidden;
`

const Error = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 20px;
  text-align: center;
`

const ButtonContainer = styled('div')`
  margin: 20px;
`

const Message = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 20px;
`

const PointList = styled('ul')`
  display: flex;
  list-style: none;
  padding: 0;
  flex-direction: row;
  align-self: center;
  align-items: center;
  margin: 20px;
`

const Point = styled('li')<{ active: boolean }>`
  margin: 0px 10px;
  background-color: ${(props) => (props.active ? props.theme.colors.background : props.theme.colors.disable)};
  height: ${(props) => (props.active ? 15 : 10)}px;
  width: ${(props) => (props.active ? 15 : 10)}px;
  border-radius: 15px;
  border: ${(props) => (props.active ? 1 : 0)}px solid ${(props) => props.theme.colors.primaryDark};
`

const NavigationLine = styled('div')`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`

const NavigationButton = styled('button')<{ prev?: boolean; disable: boolean }>`
  height: 60px;
  width: 60px;
  border-radius: 60px;
  background-color: ${(props) => (props.disable ? props.theme.colors.disable : props.theme.colors.secondaryLighter)};
  transform: rotate(${(props) => (props.prev ? '180deg' : '0deg')});
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.17));

  border: 0px;

  :hover {
    cursor: pointer;
  }
`

const CheckContainer = styled('div')`
  margin: 20px;
`
