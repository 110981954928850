import * as React from 'react'
import styled from 'styled-components'
import { Values } from 'guest/GuestScreen'

import useI18n from 'i18n/useI18n'
import { FormikErrors } from 'formik'
import { breakpoints } from 'utils/breakpoints'

import api from 'guest/api'

import Icon from 'components/icons/Icon'
import Modal from 'components/modal/Modal'
import SearchBar from 'components/input/SearchBar'
import Loader from 'components/status/Loader'

const SEARCH_MIN_LENGTH = 3

type Status = 'waitMinChars' | 'ready' | 'loading' | 'ok' | 'error'

interface Props {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<Values>>
}

const CompanionSearch = ({ setFieldValue }: Props) => {
  const i18n = useI18n()

  const [search, setSearch] = React.useState('')
  const [status, setStatus] = React.useState<Status>('waitMinChars')
  const [companions, setCompanions] = React.useState<Companion[]>([])

  React.useEffect(() => {
    if (search.length >= SEARCH_MIN_LENGTH) {
      let cancelled = false
      setStatus('ready')

      const timer = setTimeout(() => {
        setStatus('loading')

        api
          .searchCompanion(search)
          .then((res) => {
            if (!cancelled && res.companions) {
              setCompanions(res.companions)
              setStatus('ok')
            }
          })
          .catch(() => {
            if (!cancelled) {
              setStatus('error')
            }
          })
      }, 500)

      return () => {
        cancelled = true
        clearTimeout(timer)
      }
    } else {
      setCompanions([])
      setStatus('waitMinChars')
    }
  }, [search])

  const renderCompanion = (companion: Companion, index: number, list: Companion[]) => {
    const isLast = list.length === index + 1

    return (
      <li key={companion.id}>
        <React.Fragment>
          <CompanionContainer
            onClick={() => {
              setFieldValue('companion', companion)
              Modal.close()
            }}>
            <CompanionName>{`${companion.lastName} ${companion.firstName}`}</CompanionName>
          </CompanionContainer>
          {!isLast && <LineBreak />}
        </React.Fragment>
      </li>
    )
  }

  return (
    <MainContainer
      id="CompanionSearchDialog"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modalCompanionSearchHeading"
      tabIndex={-1}>
      <CrossButton onClick={Modal.close} aria-label={i18n.t(`accessibility.ariaLabels.closeModal`)}>
        <Icon name="cross" size={30} cursor="pointer" />
      </CrossButton>

      <Content>
        <Title id="modalCompanionSearchHeading">
          <label htmlFor="idSearchBarCompanion">{i18n.t('screens.guest.titleCompanionModal')}</label>
        </Title>
        <SearchBar idSearchInput="idSearchBarCompanion" value={search} onChange={setSearch} autofocus />

        <SearchResults>
          {status === 'loading' ? (
            <Loader />
          ) : status === 'error' ? (
            <MessageText>{i18n.t('screens.guest.companion.error')}</MessageText>
          ) : companions.length === 0 ? (
            <MessageText>
              {i18n.t(`screens.guest.companion.${status !== 'waitMinChars' ? 'noResult' : 'minSearch'}`, {
                min: SEARCH_MIN_LENGTH,
              })}
            </MessageText>
          ) : (
            <ListContainer>{companions.map(renderCompanion)}</ListContainer>
          )}
        </SearchResults>
      </Content>
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  position: relative;
  width: 516px;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 15px;
  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: calc(100vw - 10px);
    margin: 0px 5px;
  }
`

const CrossButton = styled('button')`
  position: absolute;
  top: 15px;
  right: 11px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;
`

const Content = styled('div')`
  margin: 59px 24px 0px;
  @media only screen and (max-width: ${breakpoints.phone}px) {
    margin: 59px 19px 0px;
  }
`
const SearchResults = styled('div')`
  display: flex;
  height: 413px;
  margin: 22px;
`
const CompanionContainer = styled('button')`
  padding: 10px 0px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;
`
const ListContainer = styled('ul')`
  overflow-y: auto;
  height: 100%;
  margin-right: -22px;
  padding-right: 22px;
  list-style: none;
`

// TEXTES

const MessageText = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  text-align: center;
`
const CompanionName = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0px;
  cursor: pointer;
  display: flex;
  flex-direction: flex-start;
`

// AUTRES

const LineBreak = styled('div')`
  height: 1px;
  background-color: ${(props) => props.theme.colors.lightGrey};
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  padding: 10px;
`

export default CompanionSearch
