import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'
import { DirectoryUser } from './types'
import { getNameToDisplay } from './utils'

import { breakpoints } from 'utils/breakpoints'
import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as DirectoryScreen from './store'

import { extractSite } from 'site/utils'
import * as SitesStore from 'site/store'
import * as ReferentielStore from 'store/referentiel/referentiel'

import UserPicture from 'authent365/UserPicture'
import Icon from 'components/icons/Icon'
import Modal from 'components/modal/Modal'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

const ICON_SIZE = 30
const ICON_MARGIN = 17
const EMPTY_BOX_SIZE = ICON_SIZE + ICON_MARGIN // Case vide pour pouvoir aligner le nom à l'image

const REGEX_FIND_DOTS = new RegExp(/\./g)
const REGEX_FIND_DASHES = new RegExp(/\-/g)
const REGEX_FIND_WHITESPACES = new RegExp(/\s+/g)

interface Props {
  user: DirectoryUser
}

const UserDetails = ({ user }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const site = useReducer(SitesStore.store, (s) => s.site)
  const referentiels = useReducer(ReferentielStore.referentielStore, (s) => s.referentiels)
  const [referentielOffice, setReferentielOffice] = React.useState<Referentiel>()

  React.useEffect(() => {
    if (referentiels.length !== 0 && !!site) {
      isOfficeSpecified()
    }
  }, [referentiels, site])

  const { mail, businessPhones, mobilePhone, /* department,  */ officeLocation, companyName, id } = user

  const favorites = useReducer(DirectoryScreen.store, (s) => s.favorites)
  const isFavorite = favorites.indexOf(id) > -1

  const clearLocationName = (locationName: string) =>
    // Suppression des points, espaces et tirets dans la localisation
    locationName
      .replace(REGEX_FIND_DOTS, '')
      .replace(REGEX_FIND_DASHES, '')
      .replace(REGEX_FIND_WHITESPACES, '')
      .toUpperCase()

  const findLocation = (locationName: string) => {
    // Localisation correspondant au string en entrée
    const location = referentiels.filter((ref) => locationName.endsWith(clearLocationName(ref.reference))) // Recherche de la salle ou du bureau
    if (location.length > 0) {
      if (location.length > 1) {
        // Sélection de la salle correspondant au bâtiment si précisé, si deux salles ont le même nom
        const correctBuildingRoom = location.find((locationRef) =>
          locationName.includes(clearLocationName(locationRef.building || ''))
        )
        if (correctBuildingRoom?.id) {
          // Une des salles trouvées est dans le bâtiment indiqué, s'il est précisé dans officeLocation
          return correctBuildingRoom
        }
      }
      return location[0]
    }
    return undefined
  }

  const isOfficeSpecified = () => {
    if (!!user.officeLocation && !!site && extractSite(user) === site.code) {
      const userLocation = clearLocationName(user.officeLocation)

      // Bureau de l'utilisateur, si défini dans la localisation
      const desk = findLocation(userLocation)

      if (desk?.id) {
        setReferentielOffice(desk)
      } else {
        // Salle dans laquelle se trouve l'utilisateur, si le bureau n'a pas été trouvé
        const room = findLocation(userLocation.slice(0, -1))
        if (room?.id) {
          setReferentielOffice(room)
        }
      }
    }
  }

  const toggleFavorite = () => {
    if (isFavorite) {
      const updatedFav = favorites.filter((f) => f !== id)
      DirectoryScreen.actions.setFav(updatedFav)
    } else {
      DirectoryScreen.actions.setFav([...favorites, id])
    }
  }

  if (!site) {
    return null
  }

  const onClickGoToMap = (referentiel: Referentiel) =>
    analytics.event(values.eventName.directory, {
      action: values.actions.redirectMap,
      site: site.name,
      type_utilisateur: analytics.typeUser(),
      espace: referentiel.room || referentiel.id,
    })

  return (
    <MainContainer
      id="UserDetailsDialog"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modalDetailsOfUser"
      tabIndex={-1}>
      <TopContainer>
        <CrossButton onClick={Modal.close} aria-label={i18n.t(`accessibility.ariaLabels.closeModal`)}>
          <Icon name="cross" size={ICON_SIZE} cursor="pointer" />
        </CrossButton>
        <UserPicture size={96} user={user} />

        <UserNameContainer>
          <IconAlignBalancer />

          <UserName id="modalDetailsOfUser">{getNameToDisplay(user)}</UserName>
          <FavoriteButton
            onClick={toggleFavorite}
            aria-label={i18n.t(`accessibility.ariaLabels.directory.favIcon${isFavorite ? '' : 'Not'}Selected`)}
            tabIndex={0}>
            <Icon
              name={isFavorite ? 'star_filled' : 'star'}
              size={ICON_SIZE}
              color={Theme.colors.secondary}
              cursor="pointer"
            />
          </FavoriteButton>
        </UserNameContainer>
      </TopContainer>

      <BottomContainer>
        {/* MAIL */}
        {!!mail && (
          <InfoContainer>
            <InfoTitle>{i18n.t('screens.phonebook.details.mail')}</InfoTitle>
            <InfoTextButton
              onClick={() => window.open(`mailto:${mail}`)}
              aria-label={i18n.t('accessibility.ariaLabels.directory.mailButton', { email: mail })}>
              {mail}
            </InfoTextButton>
          </InfoContainer>
        )}

        {/* BUSINESS PHONES */}
        {!!businessPhones &&
          businessPhones.length > 0 &&
          businessPhones.map((phone) => (
            <InfoContainer key={phone}>
              <InfoTitle>{i18n.t('screens.phonebook.details.businessPhones')}</InfoTitle>
              <PhoneTextLink
                href={`tel:${phone}`}
                aria-label={i18n.t('accessibility.ariaLabels.directory.phoneButton', { number: mobilePhone })}>
                {phone}
              </PhoneTextLink>
            </InfoContainer>
          ))}

        {/* MOBILE PHONE */}
        {!!mobilePhone && (
          <InfoContainer>
            <InfoTitle>{i18n.t('screens.phonebook.details.mobilePhone')}</InfoTitle>
            <PhoneTextLink
              href={`tel:${mobilePhone}`}
              aria-label={i18n.t('accessibility.ariaLabels.directory.phoneButton', { number: mobilePhone })}>
              {mobilePhone}
            </PhoneTextLink>
          </InfoContainer>
        )}

        {/* SERVICE */}
        {/* {!!department && (
          <InfoContainer>
            <InfoTitle>{i18n.t('screens.phonebook.details.departement')}</InfoTitle>
            <InfoText>{department}</InfoText>
          </InfoContainer>
        )} */}

        {/* OFFICE LOCATION */}
        {!!officeLocation && (
          <InfoContainer>
            <InfoTitle>{i18n.t('screens.phonebook.details.officeLocation')}</InfoTitle>
            <InfoText>{officeLocation}</InfoText>

            {!!referentielOffice && (
              <LinkContainer
                href={`/${i18n.lang}/map?location=${referentielOffice.room}`}
                onClick={() => onClickGoToMap(referentielOffice)}>
                <Icon name="map" size={20} color={Theme.colors.secondary} />
                <Label>{i18n.t('screens.meeting.booking.actions.localize')}</Label>
              </LinkContainer>
            )}
          </InfoContainer>
        )}

        {/* COMPANY */}
        {!!companyName && (
          <InfoContainer>
            <InfoTitle>{i18n.t('screens.phonebook.details.companyName')}</InfoTitle>
            <InfoText>{companyName}</InfoText>
          </InfoContainer>
        )}
      </BottomContainer>
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  margin: 15px;
  width: 376px;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: calc(100vw - 30px);
  }
`
const TopContainer = styled('div')`
  align-items: center;
  position: relative;
  border-radius: 15px 15px 0px 0px;
  padding: 20px 0px;
  background-color: ${(props) => props.theme.colors.contentBackground};
`
const BottomContainer = styled('div')`
  border-radius: 0px 0px 15px 15px;
  padding: 25px 0px 16px 26px;
  background-color: ${(props) => props.theme.colors.background};
`

const UserNameContainer = styled('div')`
  margin-top: 30px;
  flex-direction: row;
  align-items: center;
`

const InfoContainer = styled('div')`
  margin-bottom: 9px;
`

// TEXTES

const UserName = styled('h2')`
  ${(props) => props.theme.fonts.h2bold};
  margin: 0px;
  max-width: 250px;
  text-align: center;
`
const InfoTitle = styled('h3')`
  ${(props) => props.theme.fonts.labelBold};
  color: ${(props) => props.theme.colors.secondary};
  margin: 0px 0px 6px 0px;
`
const InfoText = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0px;
`

const Label = styled('span')`
  margin-left: 5px;
  ${(props) => props.theme.fonts.label}
`

// BUTTONS & LINKS

const FavoriteButton = styled('button')`
  margin-left: ${ICON_MARGIN}px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.contentBackground};
  border: 0px;
`

const CrossButton = styled('button')`
  position: absolute;
  top: 15px;
  right: 8px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.iconicGrey};
  background-color: ${(props) => props.theme.colors.contentBackground};
  border: 0px;
`

const InfoTextButton = styled('button')`
  display: flex;
  align-self: flex-start;
  ${(props) => props.theme.fonts.body};
  margin: 0px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;
`

const PhoneTextLink = styled('a')`
  display: flex;
  align-self: flex-start;
  ${(props) => props.theme.fonts.body};
  margin: 0px;
  cursor: pointer;
  text-decoration: none;
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;
`

const LinkContainer = styled('a')`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  margin: 5px 0px;
`

// AUTRES

const IconAlignBalancer = styled('div')`
  width: ${EMPTY_BOX_SIZE}px;
`

export default UserDetails
