import * as React from 'react'

import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

import { Link } from 'react-router-dom'

interface Props {
  urlEnd: string
  previousPageTitle: string
  currentPageTitle: string
}

const Tree = ({ urlEnd, previousPageTitle, currentPageTitle }: Props) => {
  const i18n = useI18n()
  return (
    <Line>
      <PreviousPageLink
        replace
        to={`/${urlEnd}`}
        aria-label={i18n.t('accessibility.ariaLabels.tree.goBackLink', {
          previousPage: i18n.t(previousPageTitle),
        })}>{`${i18n.t(previousPageTitle)} /`}</PreviousPageLink>
      <CurrentPageText>{currentPageTitle}</CurrentPageText>
    </Line>
  )
}

export default Tree

const Line = styled('div')`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
`

const CurrentPageText = styled('span')`
  ${(props) => props.theme.fonts.body};
  margin: 0px 0px 0px 4px;
  color: ${(props) => props.theme.colors.primary};
`

const PreviousPageLink = styled(Link)`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
`
