import React from 'react'
import styled from 'styled-components'

import Animators from 'components/animation/Animator'
import Button from 'components/button/Button'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'

import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as UserStore from 'store/user/user'
import * as CGUStore from './store'
import * as SitesStore from 'site/store'

import useNavigation from 'core/src/layout/useNavigation'

import api from './api'

import { breakpoints } from 'utils/breakpoints'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

// format to web format cgu, update this function to affect render
const format = (cgu: string) => cgu.replace(/<p><br>\n<\/p>/g, '').replace(/href/g, "target='_blank' href")

const CGUScreen = () => {
  const i18n = useI18n()
  const navigation = useNavigation()
  const [cgu, setCGU] = React.useState('')

  const site = useReducer(SitesStore.store, (s) => s.site)
  const user = useReducer(UserStore.store, (s) => s.user)
  const accepted = useReducer(CGUStore.store, (s) => s.accepted)
  const userAccepted = !!user && !!accepted.find((a) => a === user.id)

  React.useEffect(() => {
    api.cgu(i18n.lang).then(setCGU)

    if (!!site) {
      analytics.event(values.eventName.functionnality, {
        ecran: values.screens.cgu,
        site: site.name,
        type_utilisateur: analytics.typeUser(),
      })
    }
  }, [])

  const acceptCGU = () => {
    if (user) {
      CGUStore.actions.accept(user.id)
    }
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={i18n.t('screens.cgu.title')} />
        <Animators>
          <Content dangerouslySetInnerHTML={{ __html: format(cgu) }} />

          {!!user && !userAccepted ? (
            <ButtonContainer>
              <Button label={i18n.t('screens.cgu.accept')} onClick={acceptCGU} />
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <Button label={i18n.t('common.back')} onClick={navigation.goBack} />
            </ButtonContainer>
          )}
        </Animators>
      </ScreenContainer>
    </Main>
  )
}

export default CGUScreen

const ScreenContainer = styled('div')`
  padding: 50px 100px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const Content = styled('div')`
  font-family: 'Loreal Essentielle';
`

const ButtonContainer = styled('div')`
  margin: 40px;
  align-self: center;
  width: 206px;
  @media only screen and (max-width: ${breakpoints.medium}px) {
    width: 300px;
  }
`
