import ws from 'utils/Webservice'

const api = {
  // ANIMATIONS

  getAll: (lang: string, siteId: number, status?: AnimationStatus) =>
    ws<AnimationsList>(`/animations?siteId=${siteId}&status=${status}`, 'GET', {
      'Accept-Language': lang,
    }),

  getDetail: (lang: string, animationId: string) =>
    ws<AnimationDetail>(`/animations/${animationId}`, 'GET', {
      'Accept-Language': lang,
    }),

  // MY RESERVATIONS

  getReservations: (lang: string) =>
    ws<Bookings>(`/animations/me/bookings`, 'GET', {
      'Accept-Language': lang,
    }),

  book: (lang: string, animationId: string, slotId: string, imageRights?: 'NO_CONSENT' | 'CONSENT') =>
    ws<Booking>(
      `/animations/${animationId}/slots/${slotId}/bookings${!!imageRights ? `?imageRights=${imageRights}` : ''}`,
      'POST',
      { 'Accept-Language': lang }
    ),

  modify: (
    lang: string,
    animationId: string,
    slotId: string,
    bookingId: string,
    imageRights?: 'NO_CONSENT' | 'CONSENT'
  ) =>
    ws<Booking>(
      `/animations/${animationId}/slots/${slotId}/bookings/${bookingId}${
        !!imageRights ? `?imageRights=${imageRights}` : ''
      }`,
      'POST',
      {
        'Accept-Language': lang,
      }
    ),

  cancel: (lang: string, animationId: string, slotId: string, bookingId: string) =>
    ws<Booking>(`/animations/${animationId}/slots/${slotId}/bookings/${bookingId}`, 'PUT', { 'Accept-Language': lang }),

  // ALERTS

  getAlert: (lang: string, animationId: string) =>
    ws<UserList>(`/animations/${animationId}/alert`, 'GET', {
      'Accept-Language': lang,
    }),

  subscribe: (lang: string, animationId: string) =>
    ws<UserList>(`/animations/${animationId}/alert`, 'POST', {
      'Accept-Language': lang,
    }),

  unsubscribe: (lang: string, animationId: string) =>
    ws<UserList>(`/animations/${animationId}/alert`, 'DELETE', {
      'Accept-Language': lang,
    }),
}

export default api
