import api from './api'
import * as RegisterStore from './store'

import Logger from 'utils/Logger'
import { capitalize } from 'utils/stringUtils'

import { I18n } from 'i18n/i18n'

const DEFAULT_WIDTH = 500
const CONTENT_WIDTH = 568

const getDay = (date?: string) => (!!date ? date.split('T')[0] : '')

const getDateString = (i18n: I18n, date: string) =>
  capitalize(i18n.t('screens.register.dayMonthYear', { date: new Date(date) }))

const groupeSlotsByDay = (slots: Slot[]) =>
  slots
    .sort((a, b) => a.startDate.localeCompare(b.startDate))
    .reduce((acc, cur) => {
      const day = getDay(cur.startDate)
      const daySlots = acc[day] || []
      return { ...acc, [day]: [...daySlots, cur] }
    }, {} as { [key: string]: Slot[] })

const filterAnimations = (animations: AnimationSimple[]) =>
  animations.filter(
    (anim) =>
      !!anim.slotFirstDate && !!anim.slotLastDate && anim.slotFirstDate !== 'unknown' && anim.slotLastDate !== 'unknown'
  )

const fetchAnimations = (lang: string, siteId?: number): Promise<ScreenStatus> => {
  if (!siteId) {
    return Promise.reject('error' as ScreenStatus)
  }
  return api
    .getAll(lang, siteId, 'CREATED')
    .then(({ animations }) => {
      RegisterStore.actions.setAnimations(filterAnimations(animations))
      return 'ok' as ScreenStatus
    })
    .catch((err) => {
      Logger.error(err)
      return 'error' as ScreenStatus
    })
}

const fetchReservations = (lang: string): Promise<ScreenStatus> =>
  api
    .getReservations(lang)
    .then(({ bookings }) => {
      const sortedBookings = bookings.sort((a, b) => a.startDate.localeCompare(b.startDate))
      RegisterStore.actions.setReservations(sortedBookings)
      return 'ok' as ScreenStatus
    })
    .catch((err) => {
      Logger.error(err)
      return 'error' as ScreenStatus
    })

export {
  DEFAULT_WIDTH,
  CONTENT_WIDTH,
  getDay,
  getDateString,
  groupeSlotsByDay,
  filterAnimations,
  fetchAnimations,
  fetchReservations,
}
