import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'
import Picker from 'components/picker/PickerModal'
import TransportModeSelect from './TransportModeSelect'
import MobilitySelect from './MobilitySelect'
import Drawer from 'components/drawer/Drawer'

import api from './api'
import sitesApi from 'site/api'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as SitesStore from 'site/store'
import { breakpoints } from 'utils/breakpoints'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  navigation: Navigation
}

const decodeHtml = (str: string) => str.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec))

const InterCampusScreen = ({ navigation }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const site = useReducer(SitesStore.store, (s) => s.site)

  const [sites, setSites] = React.useState<SiteV4[]>()
  const [routes, setRoutes] = React.useState<InterCampusRoute[]>([])

  const [from, setFrom] = React.useState<SiteV4 | undefined>()
  const [end, setEnd] = React.useState<SiteV4 | undefined>()

  const [mode, setMode] = React.useState<InterCampusRoute>()

  const [defaultSelected, setDefaultSelected] = React.useState(false)

  const ends = React.useMemo(() => (!!from ? routes.filter((r) => r.departureSite === from.id) : routes), [
    routes,
    from,
  ])

  const itineraries = React.useMemo(
    () => (!!from && !!end ? routes.filter((r) => r.departureSite === from.id && r.arrivalSite === end.id) : []),
    [routes, from, end]
  )

  const route = React.useMemo(
    () => (mode ? itineraries.find((it) => it.transportMode === mode.transportMode) : undefined),
    [itineraries, mode]
  )

  const HTML_STYLE = React.useMemo(
    () => `
<style>
  .bold {
    font-family: ${theme.fonts.bodyBold.fontFamily};
    font-weight: ${theme.fonts.bodyBold.fontWeight};
  }
  .small { font-size: 13px }
  .italic { font-style: italic }
</style>`,
    [theme]
  )

  const HTML_TEXT = React.useMemo(
    () =>
      i18n.lang === 'fr'
        ? `<p class="bold">C’est parti ! Choisissez un mode de transport et découvrez la quantité de CO<sub>2</sub>e* que vous émettez (par personne) pour cette distance.</p>
    <p class="small">Les émissions de CO<sub>2</sub>e ont été calculées à l’aide des facteurs d’émissions disponibles sur la Base Carbone de l’ADEME : <a style="color: inherit" href="https://base-empreinte.ademe.fr/donnees/jeu-donnees">https://base-empreinte.ademe.fr/donnees/jeu-donnees</a>.</p>
    <p class="small italic">* CO<sub>2</sub>e : mesure des émissions en grammes d’équivalent CO<sub>2</sub></p>`
        : `<p class="bold">Let’s go! Choose a mode of transport and find out how much CO<sub>2</sub>e* you are emitting for this distance.</p>
    <p class="small">The CO<sub>2</sub>e emissions were calculated using the emission factors available on the ADEME Carbon Base.</p>
    <p class="small italic">* CO<sub>2</sub>e : carbon dioxide equivalent, a measure of the global warming potential (GWP) of greenhouse gases. It is expressed in grams per person.`,

    [i18n.lang]
  )

  React.useEffect(() => {
    // Récupération des campus
    sitesApi.all(i18n.lang).then((allSites) => setSites(allSites.sites))

    // Récupération des routes entre chaque campus
    api.get().then(({ routes }) => {
      setRoutes(routes)

      // s'il existe une route pour notre site, on le selectionne comme départ par defaut
      if (site && routes.some((r) => r.departureSite === site?.id)) {
        setFrom(site)
      }
    })
  }, [])

  React.useEffect(() => {
    if (!!site) {
      analytics.event(values.eventName.functionnality, {
        ecran: values.screens.intercampus,
        site: site.name,
        type_utilisateur: analytics.typeUser(),
      })
    }
  }, [site])

  React.useEffect(() => {
    if (!!site && routes.some((r) => r.arrivalSite === site.id && !defaultSelected)) {
      // select current site as initial from
      setFrom(site)
      setDefaultSelected(true)
    }
  }, [site, routes])

  if (!site) {
    return null
  }

  const reverseItinerary = () => {
    const previousFrom = from
    setFrom(end)
    setEnd(previousFrom)
  }

  const renderPicker = (value: SiteV4 | undefined, type: 'from' | 'to') => (
    <PickerContainer>
      <PickerInfos>
        <SelectTitle>{i18n.t(`screens.map.route.${type}`)}</SelectTitle>
        <SelectValue disabled={!value}>
          {!!value ? value.name : i18n.t('screens.intercampus.sitePlaceholder')}
        </SelectValue>
      </PickerInfos>

      <Icon name="chevron_right" color={theme.colors.unavailable} size={25} />
    </PickerContainer>
  )

  const selectSite = (type: 'from' | 'end') => {
    if (!!sites) {
      const datas =
        type === 'from'
          ? sites.filter((s) => routes.some((r) => r.departureSite === s.id))
          : sites.filter((s) => ends.some((e) => e.arrivalSite === s.id))

      Picker.open({
        title: i18n.t('components.siteChoice.campus'),
        data: datas.map((site) => ({ value: `${site.id}`, label: site.name })),
        onItemSelected: (val) => {
          setMode(undefined)

          const site = datas.find((s) => `${s.id}` === val)

          if (type === 'from') {
            setFrom(site)
          } else {
            setEnd(site)
          }
        },
      })
    } else {
      // TODO
    }
  }

  const openCovoiturage = () => {
    window.open('https://blablacardaily.com', '_blank')
  }

  return (
    <MainContainer>
      {!!route ? (
        <MapContainer>
          <Map
            src={decodeHtml(route.url)}
            allowFullScreen={false}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />

          {mode?.transportMode.startsWith('VOITURE') && (
            <BlablacarContainer onClick={openCovoiturage}>
              <Icon size={28} name="blablacar" color={theme.colors.blablacar} />
              <BlablacarInfo>{i18n.t('screens.intercampus.covoiturage')}</BlablacarInfo>
              <Icon size={17} name="chevron_right" color={theme.colors.blablacar} />
            </BlablacarContainer>
          )}
        </MapContainer>
      ) : (
        <ErrorContainer>
          <Icon name="navigate" size={160} color={theme.colors.noInfo} />
          {!from || !end ? (
            <Error>{i18n.t('screens.intercampus.noRoute')}</Error>
          ) : (
            <Error accentColor dangerouslySetInnerHTML={{ __html: HTML_STYLE + HTML_TEXT }} />
          )}
        </ErrorContainer>
      )}

      <DrawerContent>
        <Drawer icon="navigate">
          <>
            <DrawerTitle>{i18n.t('screens.intercampus.title')}</DrawerTitle>

            <SelectHeader>
              <Select onClick={() => selectSite('from')}>{renderPicker(from, 'from')}</Select>

              <ReverseButton onClick={reverseItinerary}>
                <Icon name="arrow_updown" size={25} color={theme.colors.mapItinerary} />
              </ReverseButton>

              <Select onClick={() => selectSite('end')}>{renderPicker(end, 'to')}</Select>
            </SelectHeader>

            {!!from && !!end && <TransportModeSelect modes={itineraries} mode={mode} onMode={setMode} />}

            {!!from && !!end && (
              <MobilityContainer>
                <MobilitySelect modes={itineraries} onMode={setMode} mode={mode} />
              </MobilityContainer>
            )}
          </>
        </Drawer>
      </DrawerContent>
    </MainContainer>
  )
}

export default InterCampusScreen

const MainContainer = styled('div')`
  height: 100vh;
  flex-direction: row;
`

const SelectHeader = styled('div')`
  padding: 20px 24px 10px;
`

const Select = styled('div')`
  padding-bottom: 6px;

  :hover {
    cursor: pointer;
  }
`

const SelectTitle = styled('div')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.mapItinerary};
  width: 60px;
`

const SelectValue = styled('div')<{ disabled?: boolean }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => (props.disabled ? props.theme.colors.unavailable : props.theme.colors.primaryText)};
`

const PickerContainer = styled('div')`
  flex-direction: row;
  align-items: center;
  padding: 8px;
`

const PickerInfos = styled('div')`
  flex-direction: column;
  flex: 1;
`

const ReverseButton = styled('div')`
  height: 30px;
  width: 30px;
  margin: 16px 0px;
  border: 1px solid ${(props) => props.theme.colors.mapItinerary};
  border-radius: 24px;
  background-color: ${(props) => props.theme.colors.mapDrawer};
  justify-content: center;
  align-items: center;
  align-self: center;

  :hover {
    cursor: pointer;
  }
`

const ErrorContainer = styled('div')`
  align-items: center;
  justify-content: center;
  padding: 24px;
  flex: 1;
  flex-direction: column;

  @media only screen and (min-width: ${breakpoints.medium}px) {
    padding-right: 370px;
  }
`

const Error = styled('div')<{ accentColor?: boolean }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => (props.accentColor ? props.theme.colors.accent : props.theme.colors.unavailable)};
  text-align: center;
  margin-top: 20px;

  @media only screen and (min-width: ${breakpoints.medium}px) {
    max-width: ${window.innerWidth / 2}px;
  }
`

const MapContainer = styled('div')`
  flex: 1;
  flex-direction: column;
  position: relative;
`

const MobilityContainer = styled('div')`
  margin: 16px;
`

const BlablacarContainer = styled('div')`
  position: absolute;
  bottom: 15px;
  z-index: 1;

  flex-direction: row;
  align-items: center;
  align-self: center;

  border-radius: 10px;
  padding: 12px;
  background-color: ${(props) => `${props.theme.colors.blablacarAccent}ED`};
  border: solid 1px ${(props) => `${props.theme.colors.blablacar}42`};

  :hover {
    cursor: pointer;
  }
`

const BlablacarInfo = styled('div')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.primaryText};
  margin: 0px 8px;
`

const Map = styled('iframe')`
  border: 0;
  height: calc(100% + 100px); // LSC-125
  width: calc(100% + 100px);
  margin-top: -100px;
  margin-left: -50px;
`

const DrawerContent = styled('div')`
  flex-direction: column;
`

const DrawerTitle = styled('div')`
  ${(props) => props.theme.fonts.h3Bold};
  margin-top: 50px;
  margin-bottom: 32px;
  margin-left: 24px;
`
