import * as React from 'react'
import styled from 'theme/styled-components'
import Provider from 'theme/Provider'

import Button from 'components/button/Button'
import ModalLoader from 'components/status/ModalLoader'
import Alert from 'components/alert/Alert'

import { breakpoints } from 'utils/breakpoints'
import useEscKeyPressed from 'utils/useEscKeyPressed'

import useReducer from 'store/useReducer'
import * as SitesStore from 'site/store'

import useI18n from 'i18n/useI18n'

import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock'

import * as BookingStore from 'meeting/booking/store'
import api from 'meeting/booking/api'
import { initialValues, Values as FormValues } from 'meeting/booking/BookingScreen'
import { SearchFormFields } from 'meeting/booking/SearchForm'
import { useFormik } from 'formik'

import analytics from 'utils/analytics'
import firebaseValues from 'firebaseanalytics/firebaseValues.json'

type MeetingAndReferentiel = {
  meeting: Meeting
  referentiel: ReferentielData
}

interface Props {
  title: string
  room: MeetingAndReferentiel
}
const EditBookModal = ({ title, room }: Props) => {
  const i18n = useI18n()
  const site = useReducer(SitesStore.store, (s) => s.site)

  if (!site) {
    return null
  }

  useEscKeyPressed(EditBookingPortal.close)

  const onSubmit = (vals: FormValues) => {
    ModalLoader.open()
    return api.deskBooking
      .deleteReservation(room.meeting.id, room.referentiel.email!)
      .then(() =>
        api.deskBooking.createReservation({
          start: vals.start.toISOString(),
          end: vals.end.toISOString(),
          address: room.referentiel.email!,
        })
      )
      .then(() => {
        analytics.event(firebaseValues.eventName.booking, {
          action: firebaseValues.actions.bookingOffice,
          site: site.name,
          type_utilisateur: analytics.typeUser(),
          espace: room.referentiel.id,
        })
        ModalLoader.close()
        const name = (room.referentiel.data && room.referentiel.data[`libelle_${i18n.lang}`]) || room.referentiel.email
        Alert.open({
          title: i18n.t('screens.meeting.booking.alert.success'),
          description: i18n.t('screens.meeting.booking.alert.successMessage', { room: name, date: vals.start }),
        })
        EditBookingPortal.close(true)
      })
      .catch(() => {
        ModalLoader.close()
        Alert.open({
          title: i18n.t('common.error'),
          description: i18n.t('screens.meeting.booking.alert.errorBookingMessage'),
        })
      })
  }

  const { handleSubmit, isSubmitting, setFieldValue, values } = useFormik({
    initialValues,
    onSubmit,
  })

  return (
    <MainContainer>
      <Back />
      <FocusLock autoFocus={true} returnFocus>
        <Container
          id="EditBookingModalDialog"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modalEditBookingHeading"
          tabIndex={-1}>
          <Title id="modalEditBookingHeading">{title}</Title>
          <SearchFormFields values={values} setFieldValue={setFieldValue} />
          <BookButtonContainer>
            <Button
              label={i18n.t('screens.meeting.booking.actions.edit')}
              style="small"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </BookButtonContainer>
          <BookButtonContainer>
            <Button
              label={i18n.t('common.cancel')}
              style="small"
              onClick={() => EditBookingPortal.close(false)}
              disabled={isSubmitting}
            />
          </BookButtonContainer>
        </Container>
      </FocusLock>
    </MainContainer>
  )
}

const MainContainer = styled('div')`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  opacity: 1;

  -webkit-animation: fadeIn 0.3s linear;
  animation: fadeIn 0.3s linear;
  z-index: 1;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Back = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.backgroundModal};
  border: 0px;
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px 0px 20px;
`

const Container = styled('div')`
  width: 400px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  padding: 40px;
  @media only screen and (max-width: ${breakpoints.small}px) {
    width: 200px;
  }
`
const BookButtonContainer = styled('div')`
  margin-top: 10px;
  z-index: -1;
`

let bookRoot: HTMLElement | null

const EditBookingPortal = {
  loadBookings: () => {
    api.getReservations().then((res) => BookingStore.actions.setReservations(res.schedules || []))
  },

  open: (props: Props) => {
    bookRoot = document.getElementById('book_root')

    if (bookRoot) {
      bookRoot.style.visibility = 'visible'
      ReactDOM.render(
        <Provider>
          <EditBookModal {...props} />
        </Provider>,
        bookRoot
      )
    }
  },
  close: (updateBookings?: boolean) => {
    if (bookRoot) {
      bookRoot.style.visibility = 'hidden'
      ReactDOM.unmountComponentAtNode(bookRoot)
      if (!!updateBookings) {
        EditBookingPortal.loadBookings()
      }
    }
  },
}

export default EditBookingPortal
