import ws from 'utils/Webservice'

const api = {
  all: (lang: string, siteId: number, type?: 'PRACTICAL_INFORMATION' | 'SAFE@WORK') => {
    const typeQuery = !!type ? `&typeOfInformation=${type}` : ''
    return ws<PracticalInformationList>(
      `/practical-informations?siteId=${siteId}${typeQuery}`,
      'GET',
      {},
      {
        'Accept-Language': lang,
      }
    )
  },

  get: (lang: string, id: string, type: string) =>
    ws<PracticalInformationDetails>(
      `/practical-informations/${id}`,
      'GET',
      {},
      {
        'Accept-Language': lang,
        type,
      }
    ),
}

export default api
