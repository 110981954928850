import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from '../icons/Icon'

interface Props {
  title: string
  accessibilityLabel?: string
  backgroundColor?: string
  borderColor?: string
  textColor?: string
  iconColor?: string
  icon?: IconName
  foldable?: boolean
  onToggle?: (unfolded: boolean) => void
}

const SectionHeader = ({
  title,
  accessibilityLabel,
  backgroundColor,
  borderColor,
  textColor,
  iconColor,
  icon,
  foldable,
  onToggle,
}: Props) => {
  const [theme] = useTheme()

  const [unfolded, setUnfolded] = React.useState(false)

  const onPress = () => {
    if (foldable && !!onToggle) {
      setUnfolded(!unfolded)
      onToggle(!unfolded)
    }
  }

  const iconName = unfolded ? 'chevron_top' : 'chevron_bottom'

  return (
    <HeaderContainer
      role="header"
      aria-label={accessibilityLabel}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      onClick={() => onPress()}
      foldable={foldable}>
      {!!icon && (
        <IconContainer>
          <Icon name={icon} size={20} color={iconColor || theme.colors.primary} />
        </IconContainer>
      )}
      <Header color={textColor || theme.colors.primaryText}>{title}</Header>
      {foldable && <Icon name={iconName} size={20} color={textColor} />}
    </HeaderContainer>
  )
}

export default SectionHeader

const Header = styled('h2')<{ color: string }>`
  display: flex;
  flex: 1;
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.color};
  font-weight: 700;
  margin: 0px;
`

const HeaderContainer = styled('div')<{
  backgroundColor?: string
  borderColor?: string
  noFirstBorder?: boolean
  foldable?: boolean
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 24px;
  border: 0px solid ${(props) => props.borderColor || props.theme.colors.disable};
  border-bottom-width: 1px;
  border-top-width: ${(props) => (props.noFirstBorder ? 0 : 1)}px;
  background-color: ${(props) => props.backgroundColor || props.theme.colors.background};
  ${(props) => props.foldable && 'cursor: pointer'};
`

const IconContainer = styled('div')`
  margin: 0px 8px 0px -5px;
`
