import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'
import BookModal from 'components/modal/BookModal'

import PoiDetail from './PoiDetail'
import Search from './Search'
import Route from './Route'

import * as SitesStore from 'site/store'
import useReducer from 'store/useReducer'

import useI18n from 'i18n/useI18n'

import { RouteNavigation } from './visioweb'
import { RouteAction } from './types'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'
interface Props {
  pois: Referentiel[]
  occupancies: OccupancyList
  poi?: Referentiel
  nav?: RouteNavigation
  onItemSelected: (item: Referentiel | undefined) => void
  onRoute: (action: RouteAction) => Promise<void>
}

const MapDrawer = ({ pois, occupancies, poi, nav, onItemSelected, onRoute }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  const site = useReducer(SitesStore.store, (s) => s.site)

  const [mode, setMode] = React.useState<'SEARCH' | 'PLACE' | 'ROUTE'>('SEARCH')
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    if (poi) {
      setOpen(true)
      setMode('PLACE')
    } else {
      if (mode === 'PLACE') {
        setMode('SEARCH')
      }
    }
  }, [poi])

  const openMode = (md: 'SEARCH' | 'ROUTE') => {
    if (md === 'ROUTE') {
      onItemSelected(undefined)
    }

    if (!open) {
      setMode(md)
      setOpen(true)
    } else {
      if (mode === md) {
        setOpen(false)
      } else {
        setMode(md)
      }
    }
  }

  const onBook = (room: Referentiel) => {
    BookModal.open({
      room: room,
    })
  }

  const getAriaLabelNotchButtons = (isSearchMode: boolean) => {
    const beginI18n = 'accessibility.ariaLabels.mapDrawer.'

    if (isSearchMode) {
      if (open) {
        return i18n.t(`${beginI18n}notchSearchDrawerOpen${mode === 'SEARCH' ? 'Search' : 'Route'}Focus`)
      } else {
        return i18n.t(`${beginI18n}notchSearchDrawerClose`)
      }
    } else {
      if (open) {
        return i18n.t(`${beginI18n}notchRouteDrawerOpen${mode === 'SEARCH' ? 'Search' : 'Route'}Focus`)
      } else {
        return i18n.t(`${beginI18n}notchRouteDrawerClose`)
      }
    }
  }

  if (!site) {
    return null
  }

  return (
    <MainContainer
      open={open}
      role="navigation"
      aria-label={i18n.t('accessibility.ariaLabels.navigation.secondaryDrawer')}>
      <Sticks>
        <StickButton
          $active={mode !== 'ROUTE'}
          onClick={() => openMode('SEARCH')}
          aria-label={getAriaLabelNotchButtons(true)}>
          <Icon name="search" color={mode !== 'ROUTE' ? Theme.colors.secondary : Theme.colors.primaryDark} size={28} />
        </StickButton>
        <StickButton
          $active={mode === 'ROUTE'}
          onClick={() => openMode('ROUTE')}
          aria-label={getAriaLabelNotchButtons(false)}>
          <Icon name="route" color={mode === 'ROUTE' ? Theme.colors.secondary : Theme.colors.primaryDark} size={28} />
        </StickButton>
      </Sticks>

      <Content>
        {open && (
          <>
            <Head>
              {poi && mode !== 'ROUTE' ? (
                <ActionButton
                  style={{ transform: 'rotate(180deg)' }}
                  onClick={() => onItemSelected(undefined)}
                  aria-label={i18n.t('accessibility.ariaLabels.mapDrawer.dropFocusOnDesk')}>
                  <Icon name="chevron_right" color={Theme.colors.secondary} size={25} />
                </ActionButton>
              ) : (
                <div />
              )}

              <ActionButton onClick={() => setOpen(false)} aria-label={i18n.t('accessibility.ariaLabels.drawer.close')}>
                <Icon name="cross" color={Theme.colors.secondary} size={30} cursor="pointer" />
              </ActionButton>
            </Head>

            {poi && mode !== 'ROUTE' ? (
              <PoiDetail
                poi={poi}
                occupancy={occupancies[poi.reference]}
                onBook={() => onBook(poi)}
                onItinerary={() => {
                  analytics.event(values.eventName.map, {
                    action: values.actions.go,
                    site: site.name,
                    espace: poi.reference,
                  })
                  setMode('ROUTE')
                }}
              />
            ) : mode === 'SEARCH' ? (
              <Search pois={pois} onItemSelected={onItemSelected} />
            ) : mode === 'ROUTE' ? (
              <Route pois={pois} poi={poi} nav={nav} onRoute={onRoute} />
            ) : null}
          </>
        )}
      </Content>
    </MainContainer>
  )
}

export default MapDrawer

const MainContainer = styled('nav')<{ open: boolean }>`
  display: inherit;
  flex-direction: row;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;

  transform: translate(${(props) => (!props.open ? 375 : 0)}px, 0px);
  transition-duration: 0.5s;

  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.08));
`

const Content = styled('div')`
  flex-direction: column;
  width: 375px;
  background-color: ${(props) => props.theme.colors.background};
`

const Sticks = styled('div')`
  flex-direction: column;
  margin-top: 12px;
`

const StickButton = styled('button')<{ $active: boolean }>`
  background-color: ${(props) => (props.$active ? props.theme.colors.background : props.theme.colors.lightGrey)};
  padding: 20px 15px;
  border-radius: 15px 0px 0px 15px;
  border: 0px;
  cursor: pointer;
`

const Head = styled('div')`
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
`

const ActionButton = styled('button')`
  padding: 10px;
  border: 0px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.background};
`
