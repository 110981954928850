import * as React from 'react'
import styled from 'styled-components'

import useI18n from 'i18n/useI18n'

import api from './api'
import * as GuestStore from './store'
import useReducer from 'store/useReducer'

import { onSubmitUtils } from './utils'
import { breakpoints } from 'utils/breakpoints'

import { EditedVisit, PotentialVisitor } from './types'

import useNavigation from 'core/src/layout/useNavigation'

import { useFormik } from 'formik'
import { setHours, startOfDay, formatISO, parseISO } from 'date-fns'
import { notWeekend } from 'meeting/booking/utils'

import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'
import Alert from 'components/alert/Alert'
import ModalLoader from 'components/status/ModalLoader'
import Button from 'components/button/Button'
import Loader from 'components/status/Loader'
import Tree from 'components/button/Tree'
import GuestForm from './GuestForm'
import VisitorPicker from './VisitorPicker'
import CompanionPicker from './CompanionPicker'

export interface Values {
  start: Date
  end: Date
  subject: string
  visitors: PotentialVisitor[]
  companion?: Companion
  isSelfSelected: boolean
}

export interface Props {
  id: string
}

type Status = 'loaded' | 'loading' | 'error'

const TODAY = startOfDay(notWeekend(new Date()))

const initialValues: Values = {
  start: setHours(TODAY, 8),
  end: setHours(TODAY, 18),
  subject: '',
  visitors: [],
  companion: undefined,
  isSelfSelected: true,
}

const EditVisitScreen = ({ id }: Props) => {
  const i18n = useI18n()
  const navigation = useNavigation()

  const userDetails = useReducer(GuestStore.store, (s) => s.userDetail)

  const [status, setStatus] = React.useState<Status>('loading')
  const [loading, setLoading] = React.useState(false)

  const [visitorsAdded, setVisitorsAdded] = React.useState(false)
  const [nbrVisitorsStartEdit, setNbrVisitorsStartEdit] = React.useState(0)

  React.useEffect(() => {
    api
      .getVisitDetail(parseInt(id))
      .then((res) => {
        setFieldValue('start', fromStringtoDateFormat(res.startDateTime))
        setFieldValue('end', fromStringtoDateFormat(res.endDateTime))
        setFieldValue('subject', res.meetingName)
        setNbrVisitorsStartEdit(res.visitors.length)
        setFieldValue('visitors', res.visitors)
        setFieldValue('companion', res.companion)
        setStatus('loaded')
      })
      .catch(() => setStatus('error'))
  }, [])

  React.useEffect(() => {
    if (loading) {
      ModalLoader.open()
    } else {
      ModalLoader.close()
    }
  }, [loading])

  const fromDateToStringFormat = (date: Date) => formatISO(date)
  const fromStringtoDateFormat = (date: string) => parseISO(date)

  const onSuccessFormFunction = () => {
    if (!!values.companion) {
      setLoading(true)

      const bodyUpdate: EditedVisit = {
        meetingName: values.subject,
        startDateTime: fromDateToStringFormat(values.start),
        endDateTime: fromDateToStringFormat(values.end),
        visitors: values.visitors,
        companion: values.companion,
      }

      api
        .updateVisit(parseInt(id), bodyUpdate)
        .then(() =>
          Alert.open({
            title: i18n.t('common.information'),
            description: `${i18n.t('screens.editVisit.alertSuccessEditTitle')} ${
              visitorsAdded ? i18n.t('screens.editVisit.alertDescNewVisitorsAfterEdit') : ''
            }`,
            buttons: [
              {
                label: i18n.t('common.back'),
                onClick: () => {
                  navigation.goBack()
                  Alert.close()
                },
              },
            ],
          })
        )
        .catch(() =>
          Alert.open({
            title: i18n.t('common.error'),
            description: i18n.t('screens.editVisit.errorUpdating'),
          })
        )
        .finally(() => setLoading(false))
    }
  }

  const onSubmit = (values: Values) => onSubmitUtils(values, i18n, onSuccessFormFunction, false)

  const { values, setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit,
  })

  React.useEffect(() => setVisitorsAdded(values.visitors.length > nbrVisitorsStartEdit), [values.visitors])

  const titlePage = i18n.t('screens.editVisit.title')

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={titlePage} />

        <Tree urlEnd="guest" previousPageTitle="screens.guest.title" currentPageTitle={titlePage} />

        {status === 'loading' ? (
          <Loader />
        ) : !userDetails || status === 'error' ? (
          <ErrorMessage>{i18n.t('errors.form.unknown')}</ErrorMessage>
        ) : !userDetails.hasBadge ? (
          <ErrorMessage>{i18n.t('screens.guest.noBadge')}</ErrorMessage>
        ) : (
          <EditContainer>
            <Content>
              <LeftContainer>
                <GuestForm values={values} setFieldValue={setFieldValue} handleChange={handleChange} />
                <VisitorPicker values={values} setFieldValue={setFieldValue} isEditMode />
              </LeftContainer>

              <RightContainer>
                <CompanionPicker
                  companion={values.companion}
                  isSelfSelected={values.isSelfSelected}
                  setFieldValue={setFieldValue}
                  userDetails={userDetails}
                  isEditing
                />
              </RightContainer>
            </Content>

            <ButtonsContainer>
              <ButtonContainer>
                <Button label={i18n.t('common.validate')} onClick={handleSubmit} />
              </ButtonContainer>
              <ButtonContainer>
                <Button label={i18n.t('common.back')} onClick={navigation.goBack} />
              </ButtonContainer>
            </ButtonsContainer>
          </EditContainer>
        )}
      </ScreenContainer>
    </Main>
  )
}

export default EditVisitScreen

const ScreenContainer = styled('div')`
  display: flex;
  padding: 50px 135px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const EditContainer = styled('div')`
  padding: 50px 0px 0px 65px;
`

const Content = styled('div')`
  flex-direction: row;
  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    flex-direction: column;
  }
`

const LeftContainer = styled('div')`
  flex-direction: column;
`
const RightContainer = styled('div')`
  flex-direction: column;
  margin-left: 135px;
  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    margin: 25px 0px 0px 0px;
  }
`

const ButtonsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 500px;
  margin-top: 50px;
  margin-left: 265px;
  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    margin-left: 0px;
    flex-direction: column;
  }
  @media only screen and (max-width: ${breakpoints.phone}px) {
    margin-top: 20px;
    padding-bottom: 20px;
  }
`

const ButtonContainer = styled('div')`
  margin: 0px 10px;
  display: flex;
  flex: 1;
`

const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.h3};
  margin: 50px;
`
