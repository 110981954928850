import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'

import useI18n from 'i18n/useI18n'

import { getDateString } from './utils'
import { breakpoints } from 'utils/breakpoints'

interface Props {
  detail: AnimationDetail
  multipleDays: boolean
}

const AnimationContent = ({ detail, multipleDays }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const animationTimeLabel = React.useMemo(
    () =>
      !!detail && !!detail.slotFirstDate
        ? multipleDays && !!detail.slotLastDate
          ? `${getDateString(i18n, detail.slotFirstDate)} - ${getDateString(i18n, detail.slotLastDate)}`
          : getDateString(i18n, detail.slotFirstDate)
        : '',
    [detail, multipleDays]
  )

  return (
    <ImageAndScheduleContainer>
      <InfoImage src={detail.imageUrl || require('core/src/assets/news_default.jpeg').default} alt="" />
      <InfosContainerMain>
        <InfosContainer>
          <Icon name="agenda" size={32} color={theme.colors.primaryDark} />
          <Subtitle>{animationTimeLabel}</Subtitle>
        </InfosContainer>
        <InfosContainer>
          <Icon name="pin_alt" size={32} color={theme.colors.primaryDark} />
          <Subtitle>{detail.place}</Subtitle>
        </InfosContainer>
      </InfosContainerMain>
    </ImageAndScheduleContainer>
  )
}

export default AnimationContent

const ImageAndScheduleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    flex-direction: column;
  }
`

const Subtitle = styled('h3')`
  ${(props) => props.theme.fonts.body};
`

const InfosContainerMain = styled('div')`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    flex-direction: row;
  }
`

const InfosContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.colors.contentBackground};

  padding: 24px;

  max-width: 300px;
  height: auto;

  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    margin-bottom: 16px;
    max-width: 100%;
    margin-right: 12px;
  }

  @media only screen and (max-width: ${breakpoints.phone}px) {
    max-width: 100%;
  }
`

const InfoImage = styled('img')`
  object-fit: cover;
  user-select: none;
  border-radius: 12px;
  aspect-ratio: 4/3;
  height: 300px;
  width: 400px;
  @media only screen and (max-width: ${breakpoints.small}px) {
    height: 250px;
    width: 100%;
  }
`
