import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Button from 'components/button/Button'
import Category from 'components/label/Category'
import Icon from 'components/icons/Icon'
import Modal from 'components/modal/Modal'

import useI18n from 'i18n/useI18n'

import { CONTENT_WIDTH, getDateString } from './utils'
import { capitalize } from 'utils/stringUtils'
import { breakpoints } from 'utils/breakpoints'
import useBreakpoints from 'utils/useBreakpoints'

interface Props {
  type: RegisterDrawerMode
  booking?: Booking
  mainButton: DrawerButtonProps
  secondaryButton?: DrawerButtonProps
}

const LEFT_DRAWER_WIDTH = 115
const SCROLLBAR_WIDTH = 7

const RegisterDrawerContent = ({ type, booking, mainButton, secondaryButton }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()
  const [size] = useBreakpoints()

  const bookingTimeLabel = React.useMemo(
    () =>
      !!booking
        ? getDateString(i18n, booking.startDate) +
          ' • ' +
          i18n.t('screens.register.detail.timeSlot', {
            start: new Date(booking.startDate),
            end: new Date(booking.endDate),
          })
        : '',
    [booking]
  )

  // Affichage du QR code et infos du créneau réservé
  if (type === 'QRCODE' && !!booking) {
    return (
      <ModalContainer>
        <QrCodeInfoContainer>
          <QrCode src={`data:image/jpeg;base64,${booking.qrcode}`} />

          <QrCodeTitle>{booking.animationName}</QrCodeTitle>
          <ItemDate>{bookingTimeLabel}</ItemDate>

          <ItemPlaceContainer>
            {!!booking.animationPlace && (
              <Category
                icon="compass"
                label={booking.animationPlace}
                font="link"
                iconSize={15}
                iconColor={theme.colors.iconicGrey}
              />
            )}
          </ItemPlaceContainer>
        </QrCodeInfoContainer>

        <DrawerButtonContainer>
          <Button label={i18n.t('common.close')} onClick={Modal.close} />
        </DrawerButtonContainer>
      </ModalContainer>
    )
  }

  const buttonPadding = { width: 155 }

  const unfilledButtonColors = {
    textColor: theme.colors.raspberry,
    borderColor: theme.colors.raspberry,
  }

  // Message de succès à la réservation d'un créneau
  if (['BOOK_SUCCEED', 'MODIFY_SUCCEED'].includes(type) && !!booking) {
    const slotDate = `${capitalize(
      i18n.t('screens.register.dayMonthYear', { date: new Date(booking.startDate) })
    )} • ${i18n.t('screens.register.detail.timeSlot', {
      start: new Date(booking.startDate),
      end: new Date(booking.endDate),
    })}`

    return (
      <DrawerContainer>
        <DrawerContent>
          <DrawerTitleContainer>
            <Icon name="check_circle" size={24} color={theme.colors.available} />
            <DrawerTitle>
              {i18n.t(
                `screens.${type === 'BOOK_SUCCEED' ? 'register.detail.bookSucceed' : 'myReservations.modifySucceed'}`
              )}
            </DrawerTitle>
          </DrawerTitleContainer>

          <SlotSummary>
            <ItemDate>{slotDate}</ItemDate>
            <AnimationName>{booking.animationName}</AnimationName>
            {!!booking.animationPlace && (
              <Category
                icon="compass"
                label={booking.animationPlace}
                font="link"
                iconSize={15}
                iconColor={theme.colors.iconicGrey}
              />
            )}
          </SlotSummary>
        </DrawerContent>

        <DrawerButtonContainer>
          <Button label={i18n.t('common.ok')} onClick={Modal.close} {...buttonPadding} />
        </DrawerButtonContainer>
      </DrawerContainer>
    )
  }

  // Boutons d'action (réserver, supprimer, modifier, annuler) selon les cas
  return (
    <BottomContainer position={type === 'DETAIL' ? 'relative' : 'absolute'} width={size.width - SCROLLBAR_WIDTH}>
      <ButtonsContainer justify={type === 'DETAIL' ? 'flex-end' : 'center'}>
        {type !== 'BOOK' && !!secondaryButton && (
          <Button
            label={secondaryButton.label}
            onClick={secondaryButton.onPress}
            {...unfilledButtonColors}
            {...buttonPadding}
            style="secondary"
          />
        )}
        <Button
          label={mainButton.label}
          onClick={mainButton.onPress}
          disabled={mainButton.disabled}
          {...buttonPadding}
        />
      </ButtonsContainer>
    </BottomContainer>
  )
}

export default RegisterDrawerContent

// ANIMATION INFO

const BottomContainer = styled('div')<{ position: 'absolute' | 'relative'; width: number }>`
  position: ${(props) => props.position};
  background-color: ${(props) => props.theme.colors.background};
  display: flex;
  bottom: 0;
  padding: 16px 0px 32px;

  // width = screen width - left drawer width - 2 * horizontal screen padding
  width: ${(props) => props.width - LEFT_DRAWER_WIDTH - 2 * 100}px;
  @media only screen and (max-width: ${breakpoints.medium}px) {
    width: ${(props) => props.width - LEFT_DRAWER_WIDTH - 2 * 70}px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    width: ${(props) => props.width - LEFT_DRAWER_WIDTH - 2 * 35}px;
  }
  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: ${(props) => props.width - 2 * 35}px;
  }
`

const ButtonsContainer = styled('div')<{ justify: 'center' | 'flex-end' }>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => props.justify};
  gap: 16px;
  margin: 0px 24px;
  max-width: ${CONTENT_WIDTH - 48}px;
  @media only screen and (max-width: ${breakpoints.phone}px) {
    justify-content: center;
  }
`

// BOOKING RESULT DRAWER

const DrawerContainer = styled('div')`
  display: flex;
  flex-direction: row;
  margin: 0px 24px;
  padding: 20px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: ${breakpoints.small}px) {
    flex-direction: column;
  }
`

const DrawerContent = styled('div')`
  flex-direction: column;
`

const DrawerTitleContainer = styled('div')`
  flex-direction: row;
  align-items: center;
`

const DrawerTitle = styled('p')`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.available};
  margin: 0px 0px 0px 8px;
`

const SlotSummary = styled('div')`
  margin: 16px 32px 0px 0px;
  padding: 8px;
  border-radius: 8px;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.contentBackground};

  @media only screen and (min-width: ${breakpoints.small}px) {
    min-width: 420px;
  }
`

const ItemDate = styled('p')`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.coral};
  font-size: 12px;
  margin: 0px 8px 0px 0px;
`

const AnimationName = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.primaryText};
  margin: 8px 0px 12px;
`

const DrawerButtonContainer = styled('div')`
  min-width: 90px;

  @media only screen and (min-width: ${breakpoints.small}px) {
    align-items: center;
    justify-content: flex-end;
  }
`

// QR CODE DRAWER

const ModalContainer = styled('div')`
  display: flex;
  margin: 0px 24px;
  padding: 20px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.1);
`

const QrCodeInfoContainer = styled('div')`
  align-items: center;
`

const QrCode = styled('img')`
  height: 196px;
  width: 196px;
  border-radius: 10px;
`

const QrCodeTitle = styled('p')`
  ${(props) => props.theme.fonts.h1Bold};
  color: ${(props) => props.theme.colors.primaryText};
  margin: 10px 0px 16px;
  text-align: center;
`

const ItemPlaceContainer = styled('div')`
  margin: 8px 0px 28px;
`
