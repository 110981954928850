import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Main from 'components/main/Main'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Tree from 'components/button/Tree'
import Loader from 'components/status/Loader'
import Icon from 'components/icons/Icon'
import Alert from 'components/alert/Alert'
import Modal from 'components/modal/Modal'

import SlotsList from './SlotsList'
import AnimationContent from './AnimationContent'
import RegisterDrawerContent from './RegisterDrawerContent'
import Animators from 'components/animation/Animator'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'
import * as UserStore from 'store/user/user'
import * as RegisterStore from './store'

import useNavigation from 'core/src/layout/useNavigation'

import api from './api'

import { CONTENT_WIDTH, getDateString, groupeSlotsByDay, fetchAnimations, fetchReservations } from './utils'
import Logger from 'utils/Logger'
import { breakpoints } from 'utils/breakpoints'
import { endOfDay, isAfter, isBefore } from 'date-fns'

interface Props {
  id: string
  bookingId?: string
}

const RegisterDetailScreen = ({ id, bookingId }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()
  const navigation = useNavigation()

  const site = useReducer(SiteStore.store, (s) => s.site)
  const user = useReducer(UserStore.store, (s) => s.user)
  const allReservations = useReducer(RegisterStore.store, (s) => s.reservations)

  const [status, setStatus] = React.useState<ScreenStatus>('loading')
  const [mode, setMode] = React.useState<RegisterDetailMode>(!!bookingId ? 'DETAIL' : 'BOOK')
  const [disableActions, setDisableActions] = React.useState(false)
  const [detail, setDetail] = React.useState<AnimationDetail>()
  const [selectedDay, setSelectedDay] = React.useState<string>()
  const [subscribed, setSubscribed] = React.useState(false)

  const validSlots = React.useMemo(
    () =>
      detail?.slots
        .filter((slot) => isAfter(new Date(slot.startDate), new Date()))
        .sort((a, b) => a.startDate.localeCompare(b.startDate)) || [],
    [detail]
  )

  const isFull = React.useMemo(() => detail?.isFull || validSlots.length === 0, [detail, validSlots])

  const groupedSlots = React.useMemo(() => (!!validSlots ? groupeSlotsByDay(validSlots) : []), [validSlots])

  // Liste des autres réservations de l'animation
  const alreadyBooked = React.useMemo(
    () =>
      allReservations
        .filter((r) => r.animationId === id && r.status === 'BOOKED')
        .sort((a, b) => a.startDate.localeCompare(b.startDate)),
    [id, allReservations]
  )

  // Liste des jours des autres réservations
  const alreadyBookedDates = React.useMemo(
    () => alreadyBooked.map((booking) => booking.startDate.split('T')[0]).filter(Boolean),
    [alreadyBooked]
  )

  const reservation = React.useMemo(() => alreadyBooked?.find((r) => r.id === bookingId), [alreadyBooked, bookingId])

  const reservationDate = React.useMemo(() => (!!reservation ? reservation.startDate.split('T')[0] : ''), [reservation])
  const canBook = React.useMemo(
    () =>
      !!detail &&
      // Aucune autre réservation sur cette animation
      (alreadyBookedDates.length === 0 ||
        // La seule réservation effectuée est celle que l'on souhaite modifier
        (mode === 'MODIFY' && alreadyBooked.length === 1 && alreadyBooked[0] === reservation) ||
        // L'animation accepte plusieurs réservations par utilisateur
        (!!detail.multipleSlotByUser &&
          // L'utilisateur peut réserver plusieurs créneaux le même jour
          (!!detail.multipleSlotByDay ||
            // Aucune réservation sur le jour sélectionné, ou il s'agit de la réservation à modifier
            (!!selectedDay &&
              (!alreadyBookedDates.includes(selectedDay) || (mode == 'MODIFY' && selectedDay === reservationDate)))))),
    [detail, mode, selectedDay, alreadyBookedDates, reservationDate]
  )

  const slotsDays = React.useMemo(() => (!!groupedSlots ? Object.keys(groupedSlots) : []), [groupedSlots])

  const multipleDays = React.useMemo(() => !!detail && slotsDays.length > 1, [detail, slotsDays])

  const reservationTimeLabel = React.useMemo(
    () =>
      !!reservation
        ? getDateString(i18n, reservation.startDate) +
          ' • ' +
          i18n.t('screens.register.detail.timeSlot', {
            start: new Date(reservation.startDate),
            end: new Date(reservation.endDate),
          })
        : '',
    [reservation]
  )

  React.useEffect(() => {
    fetchAnimations(i18n.lang, site?.id)
    fetchReservations(i18n.lang)
  }, [i18n.lang])

  React.useEffect(() => {
    if (!bookingId) {
      setMode('BOOK')
    }
    if (allReservations.length === 0) {
      // Si on arrive directement sur le détail (depuis un lien), il faut récupérer la liste des réservations
      fetchReservations(i18n.lang)
    }
  }, [bookingId])

  React.useEffect(() => {
    if (!!reservation && reservation.status !== 'BOOKED') {
      // La réservation a été annulée
      setStatus('error')
      navigation.push('/register')
      Alert.open({
        title: i18n.t('common.error'),
        description: i18n.t(`screens.register.detail.statusError.${reservation.status || 'CANCELLED'}`),
      })
    }
  }, [reservation])

  React.useEffect(() => {
    if (!!id) {
      setStatus('loading')
      api
        .getDetail(i18n.lang, id)
        .then((detail) => {
          if (detail.status !== 'CREATED') {
            // La page de détail et la réservation ne devraient pas être accesibles
            setStatus('error')
            navigation.push('/register')
            Alert.open({
              title: i18n.t('common.error'),
              description: i18n.t(`screens.register.detail.statusError.${detail.status || 'CANCELLED'}`),
            })
          } else {
            setDetail(detail)
            setStatus('ok')
          }
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }, [id])

  React.useEffect(() => {
    const userEmail = user?.mail
    if (!!id && !!userEmail) {
      api
        .getAlert(i18n.lang, id)
        .then(({ alertList }) => {
          setSubscribed(alertList.includes(userEmail))
        })
        .catch(Logger.error)
    }
  }, [id, user])

  React.useEffect(() => {
    if (!!slotsDays && slotsDays.length > 0) {
      setSelectedDay(slotsDays[0])
    }
  }, [slotsDays])

  const errorAlert = (err: any) => {
    Logger.error(err)
    Alert.open({ title: i18n.t('common.error'), description: i18n.t('common.errorDescription') })
  }

  const imageRightsModal = (canModify: boolean, slot: Slot, reservation?: Booking) => {
    if (!!detail && !!detail.imageRights) {
      const { optionNo, optionYes, text, title } = detail.imageRights
      Alert.open({
        title,
        description: text,
        checkBoxList: [
          {
            id: 'CONSENT',
            label: optionYes,
          },
          {
            id: 'NO_CONSENT',
            label: optionNo,
          },
        ],
        buttons: [
          {
            label: i18n.t('common.cancel'),
            onClick: () => {
              Alert.close()
              setDisableActions(false)
            },
            style: 'secondary',
          },
          {
            label: i18n.t('common.continue'),
            onClick: (checkBoxStatus) => {
              if (!!checkBoxStatus && (checkBoxStatus === 'CONSENT' || checkBoxStatus === 'NO_CONSENT')) {
                const action = () =>
                  canModify && !!reservation
                    ? api.modify(i18n.lang, id, slot.id, reservation.id, checkBoxStatus)
                    : api.book(i18n.lang, id, slot.id, checkBoxStatus)
                bookApi(action)
              }
            },
            canBeDisabled: true,
          },
        ],
      })
    }
  }

  const bookApi = (action: () => Promise<Booking>) => {
    action()
      .then(() => {
        navigation.push('/register?sectionIndex=1')
        setTimeout(
          () =>
            Alert.open({
              title: i18n.t(
                `screens.${mode === 'MODIFY' ? 'myReservations.modifySucceed' : 'register.detail.bookSucceed'}`
              ),
              titleIcon: 'check_circle',
              iconColor: theme.colors.co2,
              description: i18n.t('screens.register.detail.bookSuccedDescription'),
              buttons: [
                {
                  label: i18n.t('common.close'),
                  onClick: () => Alert.close(),
                },
              ],
            }),
          500
        )
      })
      .catch((err) => {
        Logger.error(err)
        Alert.open({
          title: i18n.t('screens.register.detail.bookFailed'),
          description: i18n.t(`screens.register.detail.${mode.toLowerCase()}Error`),
        })
      })
      .finally(() => {
        fetchAnimations(i18n.lang, site?.id)
        fetchReservations(i18n.lang)
      })
  }

  const bookModal = (slot?: Slot) => {
    if (detail?.status !== 'CREATED') {
      // La page de détail et la réservation ne devraient pas être accesibles
      Alert.open({
        title: i18n.t('common.error'),
        description: i18n.t(`screens.register.detail.statusError.${detail?.status || 'CANCELLED'}`),
      })
      navigation.push('/register')
    } else if (!!slot) {
      const canModify = mode === 'MODIFY' && !!reservation

      if (mode === 'BOOK' || canModify) {
        Alert.open({
          title: i18n.t('screens.register.detail.confirmBooking'),
          children: renderRecapSlotInfos(slot.startDate, slot.endDate),
          buttons: [
            {
              label: i18n.t('common.cancel'),
              onClick: () => {
                Alert.close()
                setDisableActions(false)
              },
              style: 'secondary',
            },
            {
              label: i18n.t('common.confirm'),
              onClick: () => {
                if (!!detail.imageRights) {
                  imageRightsModal(canModify, slot, reservation)
                } else {
                  const action = () =>
                    canModify ? api.modify(i18n.lang, id, slot.id, reservation.id) : api.book(i18n.lang, id, slot.id)
                  bookApi(action)
                }
              },
            },
          ],
        })
      } else {
        Alert.open({
          title: i18n.t('screens.register.detail.bookFailed'),
          description: i18n.t('screens.register.detail.modifyError'),
        })
      }
    }
  }

  const cancelReservation = (reservation: Booking) => {
    if (!disableActions) {
      setDisableActions(true)
      if (!!bookingId && !!reservation && !!reservation.slotId) {
        Alert.open({
          title: i18n.t('screens.register.detail.removeBooking'),
          children: renderRecapSlotInfos(reservation.startDate, reservation.endDate),
          buttons: [
            {
              label: i18n.t('common.cancel'),
              onClick: () => {
                Alert.close()
                setDisableActions(false)
              },
              style: 'secondary',
            },
            {
              label: i18n.t('common.delete'),
              onClick: () =>
                api
                  .cancel(i18n.lang, id, reservation.slotId!, bookingId)
                  .then(() => {
                    Alert.close()
                    Alert.open({
                      title: i18n.t('common.success'),
                      description: i18n.t('screens.myReservations.deleteSucceed'),
                    })
                    navigation.push('/register')
                  })
                  .catch((err) => {
                    Logger.error(err)
                    Alert.close()
                    Alert.open({ title: i18n.t('common.error'), description: i18n.t('common.errorDescription') })
                  })
                  .finally(() => setDisableActions(false)),
            },
          ],
        })
      } else {
        errorAlert(`bookingId: ${bookingId}, slotId: ${reservation?.slotId}`)
        setDisableActions(false)
      }
    }
  }

  const openQrCode = (booking: Booking) => {
    Modal.open({
      Content: () => (
        <RegisterDrawerContent
          type="QRCODE"
          booking={booking}
          mainButton={{
            label: i18n.t('common.close'),
            onPress: Modal.close,
          }}
        />
      ),
    })
  }

  const subscribe = () =>
    api
      .subscribe(i18n.lang, id)
      .then(() => setSubscribed(true))
      .catch(errorAlert)

  const unsubscribe = () =>
    api
      .unsubscribe(i18n.lang, id)
      .then(() => setSubscribed(false))
      .catch(errorAlert)

  const screenTitle =
    mode === 'BOOK'
      ? i18n.t('screens.register.detail.title')
      : mode === 'DETAIL'
      ? i18n.t('screens.myReservations.detailTitle')
      : i18n.t('screens.myReservations.modifyTitle')

  const renderRecapSlotInfos = (startDate: string, endDate: string) => {
    const dateLabel = `${getDateString(i18n, startDate)} - ${site?.name}`
    return (
      <RecapSlotContainer>
        <RecapSlotInfos>
          <RecapSlotImage src={detail?.imageUrl || require('core/src/assets/news_default.jpeg').default} alt="" />
          <RecapSlotInfosDate>
            <RecapSlotDate>{dateLabel}</RecapSlotDate>
            <RecapSlotTitle>{detail?.name}</RecapSlotTitle>
          </RecapSlotInfosDate>
        </RecapSlotInfos>
        <RecapSlotTime>
          <SlotTime bold>
            {i18n.t('screens.home.feature.timeSlot', {
              start: new Date(startDate),
              end: new Date(endDate),
            })}
          </SlotTime>
        </RecapSlotTime>
      </RecapSlotContainer>
    )
  }

  const renderAlreadyBooked = (prevBooking: Booking) => (
    <AlreadyBookedItem key={prevBooking.id}>
      <AlreadyBookedLabel>{i18n.t('screens.register.detail.alreadyBooked')}</AlreadyBookedLabel>
      <AlreadyBookedValue onClick={() => navigation.push(`/register/${id}/${prevBooking.id}`)}>
        {i18n.t(`screens.register.detail.${multipleDays ? 'dateSlot' : 'timeSlot'}`, {
          start: new Date(prevBooking.startDate),
          end: new Date(prevBooking.endDate),
        })}
      </AlreadyBookedValue>
    </AlreadyBookedItem>
  )

  const renderSubscribeButton = () => {
    if (
      !!detail &&
      detail.slots.length > alreadyBooked.length &&
      (detail.multipleSlotByUser || alreadyBooked.length === 0)
    ) {
      // On n'affiche le bouton "Me prévenir" que s'il existe au moins un créneau non réservé par l'utilisateur
      // et que l'utilisateur pourrait réserver un créneau sur cette animation (non réservée, ou multiples créneaux acceptés)

      return (
        <SubscribeContainer>
          {subscribed && (
            <SubscribeDescriptionContainer>
              <SubscribeDescriptionLabel>{i18n.t('screens.register.push.description')}</SubscribeDescriptionLabel>
            </SubscribeDescriptionContainer>
          )}
          <SubscribeButton onClick={() => (subscribed ? unsubscribe() : subscribe())}>
            <Icon name={subscribed ? 'unsubscribe' : 'subscribe'} size={24} color={theme.colors.coral} />
            <SubscribeLabel>
              {i18n.t(`screens.register.push.${subscribed ? 'unsubscribe' : 'subscribe'}`)}
            </SubscribeLabel>
          </SubscribeButton>
        </SubscribeContainer>
      )
    }
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={screenTitle} />

        <Tree urlEnd="register" previousPageTitle="screens.register.title" currentPageTitle={detail?.name || ''} />

        <ContentContainer>
          {status === 'loading' ? (
            <Loader />
          ) : status === 'error' || !detail ? (
            <ErrorContainer>
              <Error>{i18n.t('common.errorDescription')}</Error>
            </ErrorContainer>
          ) : (
            <Content>
              <Animators>
                <AnimationContent detail={detail} multipleDays={multipleDays} />
                <Title>{detail.name}</Title>
                <Resume dangerouslySetInnerHTML={{ __html: detail.description }} />
                {mode !== 'DETAIL' && (
                  <SlotsTitle>
                    {isFull
                      ? i18n.t('screens.register.detail.noAvailableSlot')
                      : i18n.t('screens.register.detail.bookSlot')}
                  </SlotsTitle>
                )}

                {mode !== 'DETAIL' ? (
                  <div>
                    {mode === 'BOOK' && alreadyBooked.length > 0 && (
                      <AlreadyBookedContainer>
                        {alreadyBooked
                          .filter((a) => !multipleDays || isBefore(new Date(), endOfDay(new Date(a.endDate))))
                          .map(renderAlreadyBooked)}
                      </AlreadyBookedContainer>
                    )}
                    {isFull ? (
                      renderSubscribeButton()
                    ) : (
                      <SlotsList
                        allSlots={validSlots}
                        booked={alreadyBooked}
                        onSlotSelect={bookModal}
                        onDaySelect={setSelectedDay}
                        canBook={canBook}
                      />
                    )}
                  </div>
                ) : (
                  !!reservation && (
                    <SlotContainer>
                      <SlotInfo>
                        <SlotTime>{reservationTimeLabel}</SlotTime>
                      </SlotInfo>
                      <IconContainer onClick={() => openQrCode(reservation)}>
                        <Icon name="qrcode" size={24} color={theme.colors.primaryDark} cursor="pointer" />
                      </IconContainer>
                      <IconContainer onClick={() => setMode('MODIFY')}>
                        <Icon name="pencil" size={24} color={theme.colors.primaryDark} cursor="pointer" />
                      </IconContainer>
                      <IconContainer onClick={() => cancelReservation(reservation)}>
                        <Icon name="cross" size={24} color={theme.colors.primaryDark} cursor="pointer" />
                      </IconContainer>
                    </SlotContainer>
                  )
                )}
              </Animators>
            </Content>
          )}
        </ContentContainer>
      </ScreenContainer>
    </Main>
  )
}

export default RegisterDetailScreen

const ScreenContainer = styled('div')`
  padding: 50px 75px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const ContentContainer = styled('div')`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: ${breakpoints.small}px) {
    padding: 16px 0;
  }
`

const ErrorContainer = styled('div')`
  flex: 1;
  padding: 24px;
  align-items: center;
  justify-content: center;
`

const Text = styled('p')`
  margin: 0px;
  padding: 0px;
`

const Error = styled(Text)`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryText};
  text-align: center;
`

const SlotsTitle = styled(Text)`
  ${(props) => props.theme.fonts.h3Bold};
  padding-top: 36px;
`

// Animation already booked

const AlreadyBookedContainer = styled('div')`
  padding: 16px 0px 8px;
  gap: 10px;
  margin: 0px;
`

const AlreadyBookedItem = styled('div')`
  max-width: ${CONTENT_WIDTH - 32}px;
  padding: 12px 8px;
  align-items: center;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.transparentYellow};
`

const AlreadyBookedLabel = styled(Text)`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.primaryDark};
`

const AlreadyBookedValue = styled(Text)`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.raspberry};
  text-decoration: underline;
  text-decoration-color: ${(props) => props.theme.colors.raspberry};
  cursor: pointer;
`

// Subscribe to push notifications

const SubscribeContainer = styled('div')`
  padding: 20px 0px;

  @media only screen and (min-width: ${breakpoints.small}px) {
    align-items: flex-start;
  }
`

const SubscribeDescriptionContainer = styled('div')`
  padding: 12px 8px;
  margin-bottom: 12px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background: rgba(255, 122, 112, 0.15);
`

const SubscribeDescriptionLabel = styled(Text)`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.primaryText};
  text-align: center;
`

const SubscribeButton = styled('button')`
  display: flex;
  flex-direction: row;
  padding: 9px 24px;
  align-items: center;
  justify-content: center;
  border-radius: 45px;
  border: 1px solid ${(props) => props.theme.colors.coral};
  background-color: ${(props) => props.theme.colors.background};
  cursor: pointer;
`

const SubscribeLabel = styled(Text)`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.coral};
  margin-left: 16px;
  justify-content: center;
`

// Slot

const SlotContainer = styled('div')`
  max-width: ${CONTENT_WIDTH - 48}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background-color: ${(props) => props.theme.colors.background};
  border: 0px solid ${(props) => props.theme.colors.lightGrey};
  border-radius: 12px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  margin-top: 32px;
`

const SlotInfo = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const SlotTime = styled(Text)<{ bold?: boolean }>`
  ${(props) => (props.bold ? props.theme.fonts.bodyBold : props.theme.fonts.subtitle)};
  color: ${(props) => props.theme.colors.primaryText};
`

const IconContainer = styled('div')`
  padding: 7px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.middleGrey};
  margin-left: 12px;
  :hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    transform: scale(1.02);
    cursor: pointer;
  }
`

const Content = styled('div')`
  @media only screen and (max-width: ${breakpoints.small}px) {
    margin-right: 0px;
    flex: 1;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  font-weight: 400;
  margin-bottom: 0;
  @media only screen and (max-width: ${breakpoints.small}px) {
    margin: 0 0 15px;
  }
`

const Resume = styled('p')`
  padding-top: 16px;
  ${(props) => props.theme.fonts.h3};
`

const RecapSlotContainer = styled('div')`
  width: 100%;
`

const RecapSlotInfos = styled('div')`
  border-radius: 12px;
  align-items: center;
  padding: 16px;
  display: flex;
  flex-direction: row;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
`

const RecapSlotTime = styled('div')`
  margin-top: 14px;
  border-radius: 12px;
  padding: 16px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.contentBackground};
`

const RecapSlotImage = styled('img')`
  object-fit: cover;
  user-select: none;
  border-radius: 4px;
  aspect-ratio: 4/3;
  height: 50px;
  width: 66px;
`

const RecapSlotInfosDate = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`

const RecapSlotDate = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
`

const RecapSlotTitle = styled('p')`
  padding-top: 6px;
  ${(props) => props.theme.fonts.bodyBold};
`
