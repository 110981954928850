import * as React from 'react'
import styled from 'theme/styled-components'

import ContentItem from './ContentItem'
import Animator from 'components/animation/Animator'
import Loader from 'components/status/Loader'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'

import api from './api'

import { breakpoints } from 'utils/breakpoints'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'
interface Props {
  folder: string
}

const WebviewFolderScreen = ({ folder }: Props) => {
  const i18n = useI18n()
  const site = useReducer(SiteStore.store, (s) => s.site!)

  const [loading, setLoading] = React.useState(true)
  const [items, setItems] = React.useState<PracticalInformationSimple[]>([])

  React.useEffect(() => {
    analytics.event(values.eventName.functionnality, {
      ecran:
        folder === 'mobility'
          ? values.screens.mobility
          : folder === 'practicallife'
          ? values.screens.practicalLife
          : values.screens.wellbeing,
      site: site.name,
      type_utilisateur: analytics.typeUser(),
    })

    api
      .webviews(site.id, folder)
      .then((l) => setItems(l.practicalInformations))
      .finally(() => setLoading(false))
  }, [])

  const renderItem = (item: PracticalInformationSimple) => {
    return <ContentItem key={item.title} content={item} folder={folder} />
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={`${i18n.t(`screens.practicalinformation.filter.${folder}`)}`} />
        <Title>{i18n.t(`screens.practicalinformation.filter.${folder}`)}</Title>
        <Animator isList gap={30}>
          {items.map(renderItem)}
        </Animator>
      </ScreenContainer>
    </Main>
  )
}

export default WebviewFolderScreen

const ScreenContainer = styled('div')`
  padding: 50px 165px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 0px 0px 30px;
`
