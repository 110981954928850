import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'
import IconButton from 'components/icons/IconButton'
import InfiniteList from 'components/list/InfiniteList'

import useI18n from 'i18n/useI18n'

import * as SitesStore from 'site/store'
import useReducer from 'store/useReducer'

import useDebounce from 'utils/useDebounce'
import sanitize from 'utils/Sanitizer'
import { sortPOIByName } from './utils'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  pois: Referentiel[]
  onItemSelected: (item: Referentiel) => void
}

const Search = ({ pois, onItemSelected }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const site = useReducer(SitesStore.store, (s) => s.site)

  const [search, bounced, setSearch] = useDebounce('')

  const sorted = React.useMemo(() => pois.sort((a, b) => sortPOIByName(a, b, i18n)), [pois])

  const items = React.useMemo(
    () =>
      sorted.filter((p) => {
        const name = (p.data && p.data[`libelle_${i18n.lang}`]) || p.room || p.reference
        return sanitize(name).includes(sanitize(bounced))
      }),
    [sorted, bounced]
  )

  const renderItem = React.useCallback((item: Referentiel) => {
    const name = (item.data && item.data[`libelle_${i18n.lang}`]) || item.room || item.reference

    return (
      <ItemButton key={item.id} onClick={() => onItemSelected(item)}>
        {name}
      </ItemButton>
    )
  }, [])

  if (!site) {
    return null
  }

  return (
    <MainContainer>
      <Title>
        <label htmlFor="idInputMapSearch">{i18n.t('screens.map.search.search')}</label>
      </Title>

      <SearchContainer role="search">
        <InputContainer>
          <Input
            id="idInputMapSearch"
            value={search}
            onChange={(evt) => setSearch(evt.target.value)}
            placeholder={i18n.t('screens.map.search.holder')}
            title={i18n.t('accessibility.titleInputText.searchBar')}
            type="text"
            onFocus={() =>
              analytics.event(values.eventName.map, {
                action: values.actions.search,
                site: site.name,
              })
            }
          />
          {search.length > 0 && (
            <IconContainer>
              <IconButton
                name="cross"
                size={15}
                color={Theme.colors.iconicGrey}
                onClick={() => setSearch('')}
                ariaLabel={i18n.t('accessibility.ariaLabels.deleteSearchInput')}
              />
            </IconContainer>
          )}
          <Icon name="search" color={Theme.colors.iconicGrey} size={15} />
        </InputContainer>
      </SearchContainer>
      {items.length === 0 ? (
        <Error>{i18n.t('screens.map.search.noResult')}</Error>
      ) : (
        <Items>
          <InfiniteList data={items} renderItem={renderItem} height={60} />
        </Items>
      )}
    </MainContainer>
  )
}

export default Search

const MainContainer = styled('div')`
  flex: 1;
`

const SearchContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.contentBackground};
  margin: 10px 0px;
  padding: 10px 20px;
`

const InputContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  border-radius: 50px;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
`

const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
`

const Input = styled('input')`
  ${(props) => props.theme.fonts.label};
  flex: 1;
  margin-right: 10px;
  border: none;
  outline: none;
`

const Items = styled('div')`
  padding: 25px 0px;
  flex: 1;
  max-height: -webkit-fill-available;
  display: block;
`

const ItemButton = styled('button')`
  ${(props) => props.theme.fonts.body};
  padding: 10px 0px;
  padding-bottom: 25px;
  margin: 0px 20px;
  border: 0px;
  border-top-width: 1px;
  background-color: ${(props) => props.theme.colors.background};
  cursor: pointer;
`
const Title = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  padding: 0px 20px;
  margin: 0px;
`

const Error = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 20px;
  text-align: center;
`
